import React from 'react';
import { useState } from 'react';
import style from './Ask.module.scss';
import axios from 'axios';
import ReactOnRails from 'react-on-rails';
import LoadingAnimation from '../UtilityComponents/LoadingAnimation';
import { Typography } from 'antd';

const Ask = (props) => {
  const [responseModalVisible, setResponseModalVisible] = useState(false);
  const [loadingAnimationVisible, setLoadingAnimationVisible] = useState(false);
  const [responseContent, setResponseContent] = useState('');
  const [questionBoxVisible, setQuestionBoxVisible] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    formData.append('user', props.user.id);
    setLoadingAnimationVisible(true);

    const response = await axios.post('/ask', formData, {
      headers: ReactOnRails.authenticityHeaders(),
    });
    if (response.data) {
      setResponseContent(response.data);
      setLoadingAnimationVisible(false);
      setQuestionBoxVisible(false);
      setResponseModalVisible(true);
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const closeModal = () => {
    setResponseModalVisible(false);
    setQuestionBoxVisible(true);
    setResponseContent('');
    window.location.href = '/ask';
  };

  return (
    <div className={style.all}>
		<section className={style.section}>
        { questionBoxVisible && (
      <div className={style.messageBox}>
      {
        loadingAnimationVisible && (
          <div className={style.loadingAnimation}>
            <LoadingAnimation />
          </div>
        )
      }
        <h3>Have a Question?</h3>
        <p>Ask me anything about Long Term Care!</p>
        <p>It helps when you ask me detailed questions!</p>
        
        <form onSubmit={handleSubmit} onKeyDown={onEnterPress}>
          <div className={style.messageBoxContent}>
            <label className={style.textMessageBox}>
              <textarea 
                className={style.textMessage}
                name='question'
                id="question"
                cols="50"
                rows="10"
                placeholder="How can I help you?"
              />
            </label>
            <button type='submit'>Ask</button>
          </div>
        </form>
      </div>
        )}

      {responseModalVisible && (
        <div className={style.modalContainer}>
          <div className={style.modal}>
            <div className={style.modalContent}>
              <span className={style.closeButton} onClick={closeModal}>
                &times;
              </span>
              <p>Answer:</p>
              <p>{responseContent.answer}</p>
              <p>Sources:</p>
              {Object.keys(responseContent.sources).map((key) => (
                <div>
                  <p>{key}: {responseContent.sources[key].page}</p>
                  <p>{responseContent.sources[key].text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
		</section>
    <section className={style.recentQuestionsContainer}>
      <h2>Your Recent Questions</h2>
      <div className={style.questions}>
        {props.questions.map((question, index) => (
          <div className={style.questionAnswer}>
            <h4>{`${index + 1}. ${question.question}`}</h4>
            <p>{question.answer ? question.answer : 'No answer give, maybe try asking a better question?'}</p>
          </div>
        ))}
      </div>
    </section>
    </div>
  );
};

export default Ask;