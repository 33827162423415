import React, { useEffect, useState } from 'react';
import style from './FlashMessage.module.scss';

const FlashMessage = ({ notice, alert }) => {
  const [messageContent, setMessageContent] = useState('');
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const message = notice || alert;
    const messageType = notice ? 'notice' : 'alert';

    if (message && message !== messageContent) {
      setMessageContent(message);
      setShowMessage(true);
      setTimeout(() => setShowMessage(true), 10);

      const timer = setTimeout(() => {
        setShowMessage(false);
        setTimeout(() => setMessageContent(''), 500);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [notice, alert]);

  useEffect (() => {
    let removalTimer;
    if (!showMessage) {
      removalTimer = setTimeout(() => setMessageContent(''), 500);
    }
    return () => clearTimeout(removalTimer);
  }, [showMessage]);

  if (!messageContent) return null;

  const messageClass = `${style.flashMessage} ${showMessage ? style.show : style.hide} ${notice ? style.notice : style.alert}`;

  return (
    <div className={messageClass}>
      {messageContent}
    </div>
  );
};

export default FlashMessage;
