import React, { useEffect } from 'react';
import { useState } from 'react';
import style from './MessagingBox.module.scss';
import axios from 'axios';
import ReactOnRails from 'react-on-rails';
import LoadingAnimation from '../UtilityComponents/LoadingAnimation';
import Select from 'react-select';

const MessagingBox = (props) => {
  const [departmentSelected, setDepartmentSelected] = useState(false);
  const [employeesSelected, setEmployeesSelected] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedRadioButton, setSelectedRadioButton] = useState('');
  const [selectedScheduleOption, setSelectedScheduleOption] = useState('');
  const [loadingAnimationVisible, setLoadingAnimationVisible] = useState(false);
  const [timeModalVisible, setTimeModalVisible] = useState(false);
  const [scheduledFormVisible, setScheduledFormVisible] = useState(false);
  const [recentMessages, setRecentMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const options = [
    { value: 'CNA', label: 'CNA' },
    { value: 'LPN/RN', label: 'LPN/RN' },
    { value: 'Activities', label: 'Activities' },
    { value: 'EVS', label: 'EVS' },
    { value: 'Dietary', label: 'Dietary' },
    { value: 'Leadership', label: 'Leadership' },
  ]

  useEffect(() => {
    const getEmployees = async () => {
      const response = await axios.get('/employees', {
        headers: ReactOnRails.authenticityHeaders(),
        params: {
          filters: ['active']
        }
      });
      const employees = response.data.employees.map((employee) => {
        return { value: employee.id, label: employee.full_name }
      })
      setEmployees(employees);
    }
    getEmployees();
  }, []);

  useEffect(() => {
    const getRecentMessages = async () => {
      const response = await axios.get('/messages/recent');
      setRecentMessages(Object.values(response.data));
    }
    getRecentMessages();
  }, []);

  const handleChange = async (e) => {
    setSelectedRadioButton(e.target.value);
    setDepartmentSelected(e.target.value === 'department' ? true : false);
    setEmployeesSelected(e.target.value === 'employee_group' ? true : false)
  };

  const handleScheduledChange = (e) => {
    setSelectedScheduleOption(e.target.value);
    if (e.target.value === 'later') {
      setScheduledFormVisible(true);
    } else {
      setScheduledFormVisible(false);
    }
  }

  const handleOptionsChange = (dropdownOptions) => {
    setSelectedOptions(dropdownOptions);
  }

  const closeModal = () => {
    setTimeModalVisible(false);
  };

  const handleMessage = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    setMessage(formData.get('textMessage'));
    setTimeModalVisible(true);
  };

  const isFutureDateTime = (date, time) => {
    const selectedDateTime = new Date(`${date}T${time}`);
    const currentDateTime = new Date();
    return selectedDateTime > currentDateTime;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    setLoadingAnimationVisible(true);

    const optionValues = selectedOptions.map((option) => option.value);
    const formData = new FormData(e.target);
    formData.append('selectedOption', selectedRadioButton);
    formData.append('values', JSON.stringify(optionValues));
    formData.append('message', message)

    if (formData.has('later')) {
      const date = formData.get('date');
      const time = formData.get('time');

      if (!isFutureDateTime(date, time)) {
        alert("Please select a future date and time.");
        setLoadingAnimationVisible(false);
        return;
      }
      
      formData.append('scheduled', true);
      formData.append('date', date);
      formData.append('time', time);
    }

    formData.delete('now');
    formData.delete('later');

    try {
      const response = await axios.post('/messages', formData, {
        headers: ReactOnRails.authenticityHeaders(),
      });
      if (response.data.success) {
        setLoadingAnimationVisible(false);
        setTimeModalVisible(false);
        alert("Message was successfully sent or scheduled!");
        e.target.reset();
      }
    } catch (error) {
      console.log(error);
      alert("There was an error sending your message.\nIf you received the message, it was sent but some might have not received it.\nPlease reach out to IT to find out who didn't receive it.");
      // Need to get a list of employees it was sent to and those it wasn't sent to.
    } finally {
      setIsSubmitting(false);
      setLoadingAnimationVisible(false);
    }
  };

  return (
    <>
		<section className={style.section}>
      <div className={style.messageBox}>
        { loadingAnimationVisible && (
          <div className={style.loadingAnimation}>
            <LoadingAnimation />
          </div>
        )}
        { timeModalVisible && (
          <div className={style.modalContainer}>
            <div className={style.modal}>
              <span className={style.closeButton} onClick={closeModal}>
                &times;
              </span>
              <h4>Schedule message for:</h4>
              <form className={style.nowOrLaterForm} onSubmit={handleSubmit}>
                <label className={style.radioButtons}>
                  <input
                    className={style.radioButton}
                    type="radio" value="now"
                    name='now'
                    checked={selectedScheduleOption === 'now'}
                    onChange={handleScheduledChange}
                  />{'Now'}
                  <input
                    className={style.radioButton}
                    type="radio" value="later"
                    name='later'
                    checked={selectedScheduleOption === 'later'}
                    onChange={handleScheduledChange}
                  />{'Later'}
                </label>
                { scheduledFormVisible && (
                    <div className={style.scheduledForm}>
                      <label className={style.dateTime}>
                        {'Date:'}
                        <input type="date" name='date' className={style.dateTimePicker} />
                      </label>
                      <label className={style.dateTime}>
                        {'Time:'}
                        <input type="time" name='time' className={style.dateTimePicker} />
                      </label>
                    </div>
                  )}
                <button type='submit' disabled={isSubmitting}>Send Message</button>
              </form>
            </div>
          </div>
          )}
        <h3>Send Message(s)</h3>
        <form onSubmit={handleMessage}>
          <div className={style.messageBoxContent}>
            <label className={style.radioButtons}>
              <input
                className={style.radioButton}
                type="radio" value='allStaff'
                checked={selectedRadioButton === 'allStaff'}
                onChange={handleChange}
              />{'All Staff'}
              <input
                className={style.radioButton}
                type="radio" value='department'
                checked={selectedRadioButton === 'department'}
                onChange={handleChange}
              />{'Department'}
              <input
                className={style.radioButton}
                type="radio" value='employee_group'
                checked={selectedRadioButton === 'employee_group'}
                onChange={handleChange}
              />{'Employees'}
              {/* <input
                className={style.radioButton}
                type="radio"
                value='allContacts'
                checked={selectedOption === 'allContacts'}
                onChange={handleChange}
              />{'All Contacts'} */}
            </label>
            {departmentSelected && 
            <div className={style.dropdown}>
              <Select
                className={style.dropdownBox}
                name="department"
                id="department"
                options={options}
                placeholder="Select Department"
                isMulti
                onChange={handleOptionsChange}
              />
            </div>
            }
            {employeesSelected && 
            <div className={style.dropdown}>
              <Select
                className={style.dropdownBox}
                name="employee"
                id="employee"
                options={employees}
                placeholder="Select Employees"
                isMulti
                onChange={handleOptionsChange}
              />
            </div>
            }
            <label className={style.textMessageBox}>
              <textarea 
                className={style.textMessage}
                name='textMessage'
                id="textMessage"
                cols="50"
                rows="10"
                placeholder="What would you like to text?"
              />
            </label>
            <button type='submit'>Next</button>
          </div>
        </form>
      </div>
      <div className={style.infoBox}>
        <h3>Info</h3>
        <p>
          The text message will be sent from the phone number <strong>(720) 605-7248</strong>.
        </p>
        <p>
          <br />
          <strong>You will also receive the message as confirmation that it was successfully sent.</strong>
        </p>
        <p>
          <br />
          Keep your messages short and sweet!
        </p>
        <p>
          <br />
          <strong>Messages will be wrapped with the following:</strong>
        </p>
        <div>
          <p>--------------------</p>  
          <p><strong>Park Forest Care Center</strong></p> 
          <p>"Your message will go here"</p>
          <p><strong>Do not reply to this message.</strong></p>
          <p>--------------------</p>
        </div>
      </div>
		</section>
    <section className={style.recentsContainer}>
      <h2>Last 10 Messages Sent</h2>
      <div className={style.recents}>
        {recentMessages.map((message, index) => (
          <div key={message.id}className={style.questionAnswer}>
            <p>
              <b>{`${index + 1}. `}</b> Message sent to {message.sent_to} by <b>{message.user}</b> on <b>{message.date}</b> at <b>{message.time}</b>
              {new Date(message.date + ' ' + message.time) > new Date() && (
                <span className={style.scheduledSpan}>pending</span>
              )}
            </p>
            <p>{message.message}</p>
          </div>
        ))}
      </div>
    </section>
    </>
  );
};

export default MessagingBox;