import React, { useState, useEffect } from 'react'
import style from './Nursing.module.scss'
import Immunization from './Immunization'
import UnvaccinatedEmployees from './UnvaccinatedEmployees'
import EmployeeImmunization from './EmployeeImmunization'
import Metrics from './Metrics'

const Nursing = (props) => {
  const [selectionType, setSelectionType] = useState('')

  const handleNursingCardClick = (type) => {
    setSelectionType(type)
  }

  const [unvaccinatedFluEmployees, setUnvaccinatedFluEmployees] = useState(
                                                                    props.employees.filter(
                                                                      employee => employee.flu_not_up_to_date
                                                                  ))
  const [unvaccinatedCovidEmployees, setUnvaccinatedCovidEmployees] = useState(
                                                                        props.employees.filter(
                                                                          employee => employee.covid_not_up_to_date
                                                                      ))

  return (
    <div className={style.nursingContainer}>
      <div className={style.nursingSelectionContainer}>
        <div />
        <div className={style.nursingCard} onClick={() => handleNursingCardClick('viewAddImmunizations')}>
          <div className={style.cardTitle}>Employee Immunizations</div>
          <div className={style.cardDescription}>Add / View Immunizations</div>
        </div>
        <div className={style.nursingCard} onClick={() => handleNursingCardClick('unvaccinated')}>
          <div className={style.cardTitle}>Unvaccinated Employees</div>
          <div className={style.cardDescription}>Flu / Covid</div>
        </div>
        <div className={style.nursingCard} onClick={() => handleNursingCardClick('tbHepB')}>
          <div className={style.cardTitle}>TB / Hep B</div>
          <div className={style.cardDescription}>Upcoming</div>
        </div>
        <div className={style.nursingCard} onClick={() => handleNursingCardClick('settings')}>
          <div className={style.cardTitle}>Settings</div>
          <div className={style.cardDescription}>**pending**</div>
        </div>
        <div />
      </div>
      { selectionType === 'viewAddImmunizations' ? 
        <EmployeeImmunization
          employees={props.employees}
        /> 
        : null }
      { selectionType === 'tbHepB' ?
        <Immunization 
          tb_screens={props.tb_screens} 
          hep_b_immunizations={props.hep_b_immunizations}
        /> 
        : null }
      { selectionType === 'unvaccinated' ? 
        <UnvaccinatedEmployees 
          fluUnvaccinatedEmployees={unvaccinatedFluEmployees} 
          covidUnvaccinatedEmployees={unvaccinatedCovidEmployees}
        /> 
        : null }
      { selectionType === 'settings' ? 
        <Metrics />
        : null }
    </div>
  )
}

export default Nursing