import React, { useState, useEffect } from 'react'
import style from './EmployeeImmunization.module.scss'
import Select from 'react-select'
import Modal from '../Modal'
import EmployeeImmunizationForm from '../Admin/ImmunizationForms/CovidFluImmunizationForm'
import axios from 'axios'
import ReactOnRails from 'react-on-rails'
import TbForm from '../Admin/ImmunizationForms/TbForm'
import HepBForm from '../Admin/ImmunizationForms/HepBForm'

const EmployeeImmunization = ({ employees }) => {
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [newImmunizationModalVisible, setNewImmunizationModalVisible] = useState(false)
  const [newImmunizationType, setNewImmunizationType] = useState('')
  const [showCloseButton, setShowCloseButton] = useState(true)
  const customStyles = {
    control: (base) => ({
      ...base,
      borderRadius: '6px',
      border: '1px solid #ddd',
      width: '300px',
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: '14px',
      color: '#999'
    }),
    menu: (base) => ({
      ...base,
      fontSize: '14px',
      zIndex: '99',
      overflow: 'visible',
      width: '300px'
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: '14px',
    })
  }
  const { tb_screen: tbScreen } = selectedEmployee || {};
  const renderTestDetails = () => {
    const hasTestData = tbScreen.blood_test_results || (Array.isArray(tbScreen.last_two_tests) && tbScreen.last_two_tests.length > 0);

    if (!hasTestData) {
      return <div className={style.data}>No Tb Tests Found</div>;
    }
  
    const isSecondTestDueAndOverdue = tbScreen.second_tb_test_due_date != null && tbScreen.last_two_tests.length === 1 && overDue(tbScreen.second_tb_test_due_date);
  
    return (
      <>
        {tbScreen.blood_test_results && (
          <>
            <div className={style.header}>Blood Test Date: </div>
            <div className={style.data}>{tbScreen.blood_test_date}</div>
            <div className={style.header}>Result: </div>
            <div className={style.data}>{tbScreen.blood_test_results}</div>
          </>
        )}
        {Array.isArray(tbScreen.last_two_tests) && tbScreen.last_two_tests.map((test, index) => (
          <React.Fragment key={index}>
            <div className={style.header}>Test {index + 1} Date: </div>
            <div className={style.data}>{test.date_administered}</div>
            <div className={style.header}>Result: </div>
            <div className={style.data}>{test.result}</div>
          </React.Fragment>
        ))}
        {isSecondTestDueAndOverdue && (
          <>
            <div className={style.header} style={{ textDecoration: 'underline' }}>Next Test Due: </div>
            <div className={style.data}>{tbScreen.second_tb_test_due_date}</div>
            <div className={style.overdueBanner}>Overdue!</div>
          </>
        )}
      </>
    );
  };

  const overDue = () => {
    const dueDate = new Date(selectedEmployee.tb_screen.second_tb_test_due_date)

    const currentDate = new Date();
    const timeDiff = currentDate - dueDate;
    const daysDiff = timeDiff / (1000 * 3600 * 24);

    const isOverdue = daysDiff > 10;
    return isOverdue;
  }

  const handleEmployeeSelect = (e) => {
    setSelectedEmployee(employees.filter(employee => employee.id === e.value)[0]);
  }

  const handleNewImmunization = (type) => {
    setNewImmunizationModalVisible(true);
    setNewImmunizationType(type);
  }

  const closeModal = () => {
    setNewImmunizationModalVisible(false);
  }

  const refreshSelectedEmployee = async () => {
    const employee = await axios.get(`/employees/${selectedEmployee.id}`, {
      params: {
        filter: 'with_latest_immunizations'
      },
      headers: ReactOnRails.authenticityHeaders()
    })
    setSelectedEmployee(employee.data)
    setNewImmunizationModalVisible(false);
  }

  return (
    <div className={style.container}>
      <div className={style.containerTitle}>Employee Immunizations</div>
      <Select
        styles={customStyles}
        className={style.employeeDropdown}
        name="employee"
        id="employee"
        options={employees.map((employee) => { return { value: employee.id, label: `${employee.full_name}` } })}
        placeholder="Select an employee"
        onChange={handleEmployeeSelect}
      />
      <div className={style.employeeInfo}>
        {selectedEmployee && (
          <>
            <div className={style.employeeInfoHeader}>
              <div className={style.employeeName}>{selectedEmployee.full_name}</div>
              <div className={style.employeeType}>
                <div className={style.boldText}>Employee Type: </div>
                <div className={style.standardText}>{selectedEmployee.employee_type}</div>
              </div>
            </div>
            {newImmunizationModalVisible && (
              <div className={style.modalOuterContainer}>
                <div className={style.modalContainer}>
                  <div className={style.modalInner}>
                    {showCloseButton && (
                      <span className={style.closeButton} onClick={closeModal}>
                        &times;
                      </span>
                    )}
                    {(newImmunizationType === 'Covid' || newImmunizationType === 'Flu') && (
                      <div className={style.modalContent}>
                        <EmployeeImmunizationForm
                          employeeId={selectedEmployee.id}
                          type={newImmunizationType}
                          onFormSubmitSuccess={refreshSelectedEmployee}
                        />
                      </div>
                    )}
                    {newImmunizationType === 'tb' && (
                      <div className={style.tbModal}>
                        <TbForm
                          employeeId={selectedEmployee.id}
                          tbScreenPresent={selectedEmployee.tb_screen === null ? false : true}
                          onFormSubmitSuccess={refreshSelectedEmployee}
                        />
                      </div>
                    )}
                    {newImmunizationType === 'hepB' && (
                      <div className={style.hepBModal}>
                        <HepBForm
                          employeeId={selectedEmployee.id}
                          onFormSubmitSuccess={refreshSelectedEmployee}
                          shotsCompleted={selectedEmployee.hep_b_immunization?.shots_completed}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className={style.employeeContainer}>
              <div className={style.immunizationContainer}>
                <div className={style.primaryTitle}>Covid Immunization</div>
                <div className={style.covidImmunizationInfo}>
                  {selectedEmployee.latest_covid_immunization ? (
                    <div className={style.immunizationInfo}>
                      <div className={style.infoTable}>
                        <div className={style.header}>Status: </div>
                        <div className={style.data}>{selectedEmployee.latest_covid_immunization.status}</div>

                        {selectedEmployee.latest_covid_immunization.status === "Received" ? (
                          <>
                            <div className={style.header}>Date Administered: </div>
                            <div className={style.data}>{selectedEmployee.latest_covid_immunization.date_administered}</div>
                            <div className={style.header}>Received On Site: </div>
                            <div className={style.data}>{selectedEmployee.latest_covid_immunization.received_onsite ? "Yes" : "No"}</div>
                          </>
                        ) : (
                          <>
                            <div className={style.header}>Decline Reason: </div>
                            <div className={style.data}>{selectedEmployee.latest_covid_immunization.decline_reason}</div>
                            <div className={style.header}>Decline Date: </div>
                            <div className={style.data}>{selectedEmployee.latest_covid_immunization.decline_date}</div>
                          </>
                        )
                        }
                      </div>
                    </div>
                  ) : <div className={style.noneFound}>No Immunization Found</div>
                  }
                  <hr />
                  <div className={style.buttonContainer}>
                    <button className={style.addImmunizationButton} onClick={() => handleNewImmunization('Covid')}>Add Immunization</button>
                  </div>
                </div>
              </div>
              <div className={style.immunizationContainer}>
                <div className={style.primaryTitle}>Flu Immunization</div>
                {selectedEmployee.latest_flu_immunization ? (
                  <div className={style.immunizationInfo}>
                    <div className={`${style.infoTable} ${style.specialInfoTable}`}>
                      <div className={style.header}>Status: </div>
                      <div className={style.data}>{selectedEmployee.latest_flu_immunization.status}</div>

                      {selectedEmployee.latest_flu_immunization.status === "Received" || selectedEmployee.latest_flu_immunization.status === "Not Up to Date" ? (
                        <>
                          <div className={style.header}>Date Administered: </div>
                          <div className={style.data}>{selectedEmployee.latest_flu_immunization.date_administered}</div>
                          <div className={style.header}>Received On Site: </div>
                          <div className={style.data}>{selectedEmployee.latest_flu_immunization.received_onsite ? "Yes" : "No"}</div>
                        </>
                      ) : (
                        <>
                          <div className={style.header}>Decline Reason: </div>
                          <div className={style.data}>{selectedEmployee.latest_flu_immunization.decline_reason}</div>
                          <div className={style.header}>Decline Date: </div>
                          <div className={style.data}>{selectedEmployee.latest_flu_immunization.decline_date}</div>
                        </>
                      )
                      }
                    </div>
                  </div>
                ) : <div className={style.noneFound}>No Immunization Found</div>
                }
                <hr />
                <div className={style.buttonContainer}>
                  <button className={style.addImmunizationButton} onClick={() => handleNewImmunization('Flu')}>Add Immunization</button>
                </div>
              </div>
              {/* Below we need TB Status */}
              <div className={style.immunizationContainer}>
                <div className={style.primaryTitle}>TB Status</div>
                {selectedEmployee.tb_screen ? (
                  <div className={style.immunizationInfo}>
                    <div className={style.infoTable}>
                      <div className={style.header}>Vaccinated?</div>
                      <div className={style.data}>{selectedEmployee.tb_screen.vaccinated ? "Yes" : "No"}</div>
                    </div>
                    <div className={style.secondaryTitle}>TB Tests</div>
                    <div className={style.infoTable}>
                      {renderTestDetails()}
                    </div>
                    {selectedEmployee.tb_screen.chest_xray_required === true && (
                      <>
                        <div className={style.secondaryTitle}>Last Chest Xray</div>
                        {selectedEmployee.tb_screen.chest_xray_date != null ? (
                          <div className={style.infoTable}>
                            <div className={style.header}>Date Received: </div>
                            <div className={style.data}>{selectedEmployee.tb_screen.chest_xray_date}</div>
                            <div className={style.header}>Results: </div>
                            <div className={style.data}>{selectedEmployee.tb_screen.chest_xray_result}</div>
                          </div>
                        ) : (
                          <>
                            <div className={style.data}>No Chest Xrays Found</div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                ) : <div className={style.noneFound}>No Immunization Found</div>
                }
                <hr />
                <div className={style.buttonContainer}>
                  <button className={style.addImmunizationButton} onClick={() => handleNewImmunization('tb')}>Add Tb Record</button>
                </div>
              </div>
              {/* Below we need Hep B Status */}
              <div className={style.immunizationContainer}>
                <div className={style.primaryTitle}>Hep B Status</div>
                {selectedEmployee.hep_b_immunization ? (
                  <div className={style.immunizationInfo}>
                    <div className={style.infoTable}>
                      <div className={style.header}>Status: </div>
                      <div className={style.data}>{selectedEmployee.hep_b_immunization.status}</div>
                      {(selectedEmployee.hep_b_immunization.status === "Received" || selectedEmployee.hep_b_immunization.status === 'Pending') && (
                        <>
                          <div className={style.header}>Vaccines Completed: </div>
                          <div className={style.data}>{selectedEmployee.hep_b_immunization.shots_completed}</div>
                          {selectedEmployee.hep_b_immunization.hep_b_shots.map((vaccine, index) => (
                            <React.Fragment key={index}>
                              <div className={style.header}>- Vaccine {index + 1} Date: </div>
                              <div className={style.data}>{vaccine.date_administered}</div>
                            </React.Fragment>
                          ))}
                        </>
                      )}
                      {selectedEmployee.hep_b_immunization.status === 'Declined' && (
                        <>
                          <div className={style.header}>Decline Reason: </div>
                          <div className={style.data}>{selectedEmployee.hep_b_immunization.decline_reason}</div>
                        </>
                      )}
                    </div>
                  </div>
                ) : <div className={style.noneFound}>No Immunization Found</div>
                }
                <hr />
                <div className={style.buttonContainer}>
                  <button className={style.addImmunizationButton} onClick={() => handleNewImmunization('hepB')}>Add Immunization</button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>

  )
}

export default EmployeeImmunization