import React from "react";
import style from "./UpdatePassword.module.scss";
import axios from "axios";

const UpdatePassword = (token) => {
  const handleSubmit = async (e) => {
    e.preventDefault()

    const formData = new FormData(e.target)
    formData.append('reset_password_token', token.token)
    const formObject = {}
    formObject.user = Object.fromEntries(formData.entries())
    try {
      const response = await axios.put(`/password`, formObject, {
        headers: ReactOnRails.authenticityHeaders(),
      })
      if (response.data.message === 'Password updated') {
        alert(`Password updated.`)
      }
    } catch (err) {
      alert(`Password not updated. ${err.response.data.error}`)
    }
    e.target.reset()
    window.location.href = '/'
  }

  return (
    <div className={style.updatePasswordContainer}>
    <h2>Update Password</h2>
    <form className={style.passwordForm} onSubmit={handleSubmit}>
      <label>
        <input
          className={style.passwordBox}
          type="password"
          name="password"
          id="new_password"
          placeholder="New Password"
        />
      </label>
      <label>
        <input
          className={style.passwordBox}
          type="password"
          name="password_confirmation"
          id="new_password_confirmation"
          placeholder="New Password Confirmation"
        />
      </label>
      <button type="submit">Update</button>
    </form>
    </div>
  )
}

export default UpdatePassword