import React, { useState, useEffect } from 'react'
import axios from 'axios'
import style from './TbForm.module.scss'
import Select from "react-select"
import ReactOnRails from 'react-on-rails'
import { DatePicker } from "rsuite"

const TbForm = ({employeeId, tbScreenPresent, onFormSubmitSuccess}) => {
  const [xrayRequiredSelection, setXrayRequiredSelection] = useState(null)
  const [receivedOnsite, setReceivedOnsite] = useState(null)
  const [chestXrayDate, setChestXrayDate] = useState('')
  const [dateAdministered, setDateAdministered] = useState('')
  const [leadership, setLeadership] = useState([])
  const [ppds, setPpds] = useState([])
  const [newTestVisible, setNewTestVisible] = useState(false)
  const [dateEvaluated, setDateEvaluated] = useState('')
  const [testType, setTestType] = useState('')
  const customStyles = {
    control: (base) => ({
      ...base,
      borderRadius: '6px',
      border: '1px solid #ddd',
      minWidth: '210px',
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: '14px',
      color: '#999'
    }),
    menu: (base) => ({
      ...base,
      fontSize: '14px',
      zIndex: '99',
      overflow: 'visible'
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: '14px',
    })
  }

  const handleSetReceivedOnsite = (e) => {
    setReceivedOnsite(e)
    if (e.value != false) {
      setTestType(null)
    }
  }

  useEffect(() => {
    const fetchLeadership = async () => {
      const response = await axios.get('/employees', {
        params: {
          filter: 'leadership'
        }
      });
      const leadership = response.data.employees
      setLeadership(leadership)
    }
    const fetchPpds = async () => {
      const response = await axios.get(`/tb_ppds`);
      const ppds = response.data.tb_ppds
      setPpds(ppds)
    }
    fetchPpds()
    fetchLeadership()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const formObject = Object.fromEntries(formData.entries())
    const tbScreen = {}
    tbScreen.employee_id = employeeId

    if(!tbScreenPresent) {
      tbScreen.vaccinated = formObject.vaccinated
    }

    if(xrayRequiredSelection) {
      tbScreen.chest_xray_date = chestXrayDate.toLocaleDateString()
      tbScreen.chest_xray_result = formObject.chest_xray_result
    }

    if (newTestVisible) {
      const tbTest = {}
      if(testType === 'blood_test') {
        tbScreen.test_type = testType
        tbScreen.blood_test_results = formObject.result
        tbScreen.blood_test_brand = formObject.blood_test_brand
        tbScreen.blood_test_date = dateAdministered.toLocaleDateString()
      } else {
        tbTest.date_administered = dateAdministered.toLocaleDateString(),
        tbTest.result = formObject.result,
        tbTest.received_onsite = receivedOnsite,
        tbTest.date_evaluated = dateEvaluated.toLocaleDateString()

        if (receivedOnsite) {
          tbTest.site = formObject.site;
          tbTest.tb_ppd_id = formObject.ppd;
          tbTest.induration = formObject.induration;
          tbTest.administered_by_id = formObject.administered_by_id;
        }
      }

      if(Object.values(tbTest).length > 1) {
        tbScreen.tb_tests_attributes = tbScreen.tb_tests_attributes || [];
        tbScreen.tb_tests_attributes.push(tbTest);
      }
    }

    try {
      const response = await axios.post(`/tb_screens`, tbScreen, {
        headers: ReactOnRails.authenticityHeaders()
      })

      if(response.status === 200) {
        alert('TB Screen has been added')
        onFormSubmitSuccess()
      }
    } catch(error) {
      alert(error.response.data.message)
    }
  }

  return (
    <div>
      { true && (
        <form onSubmit={handleSubmit}>
          <div className={style.title}>Tb Test</div>
          <div className={style.container}>
            <div className={style.formTable}>
              { !tbScreenPresent && (
                <>
                  <label htmlFor="vaccinated">Vaccinated for TB?</label>
                  <Select
                    styles={ customStyles }
                    className={style.dropdownBox}
                    name="vaccinated"
                    id="vaccinated"
                    options={
                      [{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
                    placeholder="Yes/No"
                    required={true}
                    isSearchable={false}
                    menuPlacement='auto'
                  />
                </>
              )}
              <label htmlFor="chest_x_ray_required">New chest x-ray received?</label>
              <Select
                styles={ customStyles }
                className={style.dropdownBox}
                name="chest_x_ray_required"
                id="chest_x_ray_required"
                options={
                  [{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
                placeholder="Yes/No"
                onChange={(e) => {setXrayRequiredSelection(e?.value)}}
                required={true}
                isSearchable={false}
                menuPlacement='auto'
              />
              { xrayRequiredSelection && (
                <>
                  <label htmlFor="chest_xray_date">Date of chest x-ray:</label>
                  <DatePicker
                    name="chest_xray_date"
                    id="chest_xray_date"
                    className={style.datePicker}
                    onChange={(e) => setChestXrayDate(e)}
                    oneTap
                    format="MM/dd/yyyy"
                    required={true}
                  />
                  <label htmlFor="chest_xray_result">Result of chest x-ray:</label>
                  <Select
                    styles={ customStyles }
                    className={style.dropdownBox}
                    name="chest_xray_result"
                    id="chest_xray_result"
                    options={
                      [{value: "positive", label: 'Positive'}, {value: "negative", label: 'Negative'}]}
                    placeholder="Positive/Negative"
                    required={true}
                    isSearchable={false}
                    menuPlacement='auto'
                  />
                </>
              )}
              { xrayRequiredSelection === false && (
                <>
                  <label htmlFor="new_skin_test_administered">Add New TB Test?</label>
                  <Select
                    styles={ customStyles }
                    className={style.dropdownBox}
                    name="new_skin_test_administered"
                    id="new_skin_test_administered"
                    options={
                      [{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
                    placeholder="Yes/No"
                    onChange={(e) => {setNewTestVisible(e?.value)}}
                    required={true}
                    isSearchable={false}
                    menuPlacement='auto'
                  />
                </>
              )}
              { (newTestVisible) && (
                <>
                  <label htmlFor="received_onsite">Was the test received onsite?</label>
                  <Select
                    styles={ customStyles }
                    className={style.dropdownBox}
                    name="received_onsite"
                    id="received_onsite"
                    options={
                      [{value: true, label: 'Yes'}, {value: false, label: 'No'}]}

                    placeholder="Yes/No"
                    onChange={(e) => {handleSetReceivedOnsite(e.value)}}
                    required={true}
                    isSearchable={false}
                    menuPlacement='auto'
                  />
                  <label htmlFor="date_administered">Date Administered:</label>
                  <DatePicker
                    name="date_administered"
                    id="date_administered"
                    className={style.datePicker}
                    onChange={(e) => setDateAdministered(e)}
                    oneTap
                    format="MM/dd/yyyy"
                    required={true}
                  />
                  <label htmlFor="result">Result:</label>
                  <Select
                    styles={ customStyles }
                    className={style.dropdownBox}
                    name="result"
                    id="result"
                    options={[{value: 'positive', label: 'Positive'}, {value: 'negative', label: 'Negative'}]}
                    placeholder="Positive/Negative"
                    required={true}
                    isSearchable={false}
                    menuPlacement='auto'
                  />
                  { receivedOnsite === true && (
                    <>
                      <label htmlFor="date_evaluated">Date Evaluated:</label>
                      <DatePicker
                        name="date_evaluated"
                        id="date_evaluated"
                        className={style.datePicker}
                        onChange={(e) => setDateEvaluated(e)}
                        oneTap
                        format="MM/dd/yyyy"
                        required={true}
                      />
                      <label htmlFor="site">Site:</label>
                      <Select
                        styles={ customStyles }
                        className={style.dropdownBox}
                        name="site"
                        id="site"
                        options={
                          [{value: 'left_forearm', label: 'Left Forearm'}, {value: 'right_forearm', label: 'Right Forearm'}]}
                        placeholder="Left/Right"
                        required={true}
                        isSearchable={false}
                        menuPlacement='auto'
                      />
                      <label htmlFor="induration">Induration in mm:</label>
                      <input
                        className={style.textInput}
                        type="text"
                        name="induration"
                        id="induration"
                      />
                      <label htmlFor="administered_by_id">Administered By:</label>
                      <Select
                        styles={ customStyles }
                        className={style.dropdownBox}
                        name="administered_by_id"
                        id="administered_by_id"
                        options={leadership.map((employee) => { return { value: employee.id, label: `${employee.full_name}` } })}
                        placeholder="Select Employee"
                        required={true}
                        isSearchable={false}
                        menuPlacement='auto'
                      />
                      <label htmlFor="ppd">PPD:</label>
                      <Select
                        styles={ customStyles }
                        className={style.dropdownBox}
                        name="ppd"
                        id="ppd"
                        options={ppds.map((ppd) => { return { value: ppd.id, label: `${ppd.brand}` } })}
                        placeholder="Select PPD"
                        required={true}
                        isSearchable={false}
                        menuPlacement='auto'
                      />
                    </>
                  )}
                  { receivedOnsite === false && (
                    <>
                      <label htmlFor="test_type">Test Type:</label>
                      <Select
                        styles={ customStyles }
                        className={style.dropdownBox}
                        name="test_type"
                        id="test_type"
                        options={[{value: 'ppd', label: '2-Step PPD'}, {value: 'blood_test', label: "Blood Test"}] }
                        onChange={(e) => setTestType(e.value)}
                        placeholder="Select PPD"
                        required={true}
                        isSearchable={false}
                        menuPlacement='auto'
                      />
                      <div className={`${style.subText} ${style.twoColumns}`}>If this is a 2-step PPD received off-site, please submit one at a time.</div>
                      { testType === 'ppd' && (
                        <>
                          <label htmlFor="date_evaluated">Date Evaluated:</label>
                          <DatePicker
                            name="date_evaluated"
                            id="date_evaluated"
                            className={style.datePicker}
                            onChange={(e) => setDateEvaluated(e)}
                            oneTap
                            format="MM/dd/yyyy"
                            required={true}
                          />
                        </>
                      )}
                    </>
                  )}
                  { testType === 'blood_test' && (
                    <>
                      <label htmlFor="blood_test_brand">Which Blood Test?</label>
                      <Select
                        styles={ customStyles }
                        className={style.dropdownBox}
                        name="blood_test_brand"
                        id="blood_test_brand"
                        options={[{value: 'quantiferon', label: 'Quantiferon'}, {value: 't_spot', label: "T-Spot"}] }
                        placeholder="Quantiferon/T-Spot"
                        required={true}
                        isSearchable={false}
                        menuPlacement='auto'
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={style.buttonContainer}>
            <button className={style.addImmunizationButton} type="submit">Submit</button>
          </div>
        </form>
      )}
    </div>
  )
}

export default TbForm