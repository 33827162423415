import React from "react";
import { useState } from "react";
import style from "./NavBar.module.scss";
import AuthButton from "../Authentication/AuthButton";

const NavBar = (user) => {
  const [menuOpen, setMenuOpen] = useState(true);

	return (
		<>
    <header> 
      <div className={style.container}>
       <a href="/"><img className={style.logo} src={'/logo_name.png'} /></a>
        <nav>
          { user.user.id ?
            <>
              <a className={style.menuLinks} href="/">Home</a>
              <a className={style.menuLinks} href="/ask">Ask</a>
              { user.user.role === 'NurseManager' || user.user.role === 'Manager' || user.user.role === 'Admin' ? <a className={style.menuLinks} href="/messages">Messages</a> : null}
              { user.user.role === 'NurseManager' || user.user.role === 'Admin' ? <a className={style.menuLinks} href="/nursing">Nursing</a> : null}
              { user.user.role === 'Admin' ? <a className={style.menuLinks} href="/admin">Admin</a> : null}
            </>
          : null }
			  </nav>
        <button className={style.hamburger} onClick={() => setMenuOpen(!menuOpen)}>
          <div className={style.bar}></div>
        </button>
        { user.user.first_name ? 
          <AuthButton className={style.authButton } type={"logout"}/> 
          : 
          <AuthButton className={style.authButton } type={"login"}/>
        }
      </div>
    </header>
      <nav className={menuOpen ? style.mobileNav : style.mobileNavActive}>
        <a href="/">Home</a>
        { user.user.id ? 
          <>
            <a href="/ask">Ask</a>
            { user.user.role === 'NurseManager' || user.user.role === 'Manager' || user.user.role === 'Admin' ? <a href="/messages">Messages</a> : null }
            { user.user.role === 'NurseManager' || user.user.role === 'Admin' ? <a href="/nursing">Nursing</a> : null }
            { user.user.role === 'Admin' ? <a href="/admin">Admin</a> : null }
          </>
        : null }

      </nav>
		</>
	);
}

export default NavBar;