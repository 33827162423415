import React, { useEffect, useState } from 'react'
import axios from 'axios'
import style from './Modal.module.scss'
import Select from "react-select"

const Modal = ({children, closeModal, showCloseButton = true}) => {
  return (
    <div className={style.modalContainer}>
      <div className={style.modal}>
        <div className={style.modalContent}>
          { showCloseButton && (
            <span className={style.closeButton} onClick={closeModal}>
              &times;
            </span>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal