import React, { useState, useEffect } from 'react'
import axios from 'axios'
import style from './Metrics.module.scss'
import { DatePicker } from "rsuite"

const Metrics = () => {
  const [lastFluSeason, setLastFluSeason] = useState("")
  const [lastCovidSeason, setLastCovidSeason] = useState("")
  const [formVisible, setFormVisible] = useState(false)
  const [showCloseButton, setShowCloseButton] = useState(true)
  const [startDate, setStartDate] = useState('')
  const [seasonSelected, setSeasonSelected] = useState('')

  const handleAddSeason = (type) => {
    setSeasonSelected(type)
    setFormVisible(true)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (seasonSelected === 'Flu') {
      const response = await axios.post('/flu_seasons', {
        start_date: startDate.toDateString()
      }, {
        headers: ReactOnRails.authenticityHeaders()
      })
      setLastFluSeason(response.data.start_date)
      setFormVisible(false)
    }
    if (seasonSelected === 'Covid') {
      const response = await axios.post('/covid_seasons', {
        start_date: startDate.toDateString()
      }, {
        headers: ReactOnRails.authenticityHeaders()
      })
      setLastCovidSeason(response.data.start_date)
      setFormVisible(false)
    }
  }

  const closeModal = () => {
    setFormVisible(false)
  }

  useEffect(() => {
    const fetchLastFluSeason = async () => {
      const response = await axios.get('/flu_seasons')
      const fluSeasonDate = response.data.start_date
      setLastFluSeason(fluSeasonDate)
    }
    const fetchLastCovidSeason = async () => {
      const response = await axios.get('/covid_seasons')
      const covidSeasonDate = response.data.start_date
      setLastCovidSeason(covidSeasonDate)
    }
    fetchLastFluSeason()
    fetchLastCovidSeason()
  }, [lastFluSeason, lastCovidSeason])


  return (
    <div className={style.container}>
      {formVisible && (
        <div className={style.modalOuterContainer}>
          <div className={style.modalContainer}>
            <div className={style.modalInner}>
              {showCloseButton && (
                <span className={style.closeButton} onClick={closeModal}>
                  &times;
                </span>
              )}
              {true && (
                <div className={style.modalContent}>
                  <form onSubmit={handleSubmit}>
                    <div className={style.formTitle}>Add New {seasonSelected} Season</div>
                    <div className={style.formContainer}>
                      <div className={style.formTable}>
                        <label htmlFor="start_date">Start Date</label>
                        < DatePicker
                          className={style.datePicker}
                          name="start_date"
                          id="start_date"
                          format='MM/dd/yyy'
                          oneTap
                          onChange={(e) => setStartDate(e)}
                          placeholder="MM/DD/YYYY"
                          cleanable={true}
                          required={true}
                          placement='auto'
                        />
                        <div className={style.buttonContainer}>
                          <button className={style.addSeasonDateButton} type="submit">Add Season</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div>
        <h2>Update Immunizations</h2>
        <div className={style.seasonContainer}>
          <div className={style.addFluSeasonButton} onClick={() => handleAddSeason('Flu')}>
            <div className={style.buttonText}>Add New Flu Season</div>
          </div>
          <div className={style.fluSeason}>
            <div className={style.cardTitle}>Current Flu Season</div>
            <div className={style.cardTitle}>Start Date</div>
            <div className={style.dateCard}>
              <div className={style.seasonDate}>{lastFluSeason}</div>
            </div>
          </div>
          <div className={style.addCovidSeasonButton} onClick={() => handleAddSeason("Covid")}>
            <div className={style.buttonText}>Add New Covid Season</div>
          </div>
          <div className={style.covidSeason}>
            <div className={style.cardTitle}>Current Covid Season</div>
            <div className={style.cardTitle}>Start Date</div>
            <div className={style.dateCard}>
              <div className={style.seasonDate}>{lastCovidSeason}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Metrics