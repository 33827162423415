import React from 'react'
import style from './AdminMenu.module.scss'

const AdminMenu = ({ setSelectedOption }) => {
  return (
    <div className={style.adminMenuContainer}>
      <h3>Admin Menu</h3>
      <ul className={style.menuList}>
        <li><button onClick={() => setSelectedOption('createEmployee')}>Create Employee</button></li>
        <li><button onClick={() => setSelectedOption('manageEmployees')}>Employees</button></li>
        <li><button onClick={() => setSelectedOption('Accounts')}>View Users</button><br /></li>
        <li><button onClick={() => setSelectedOption('createUser')}>Create Ask User</button></li>
        <li><button onClick={() => setSelectedOption('metrics')}>Immunization Metrics</button></li>
      </ul>
    </div>
  )
}

export default AdminMenu