import React, { useEffect, useState } from 'react'
import ActionCable from 'actioncable'
import style from './SoundNotifications.module.scss'

const SoundNotifications = () => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    const thing = [1];
    const cable = ActionCable.createConsumer('/cable');
    const subscription = cable.subscriptions.create(
      {
        channel: 'MqttChannel',
        room_id: thing
      }, 
      {
        disconnected: () => console.log('disconnected'),
        connected: () => console.log('connected'),
        received: (data) => {
          setMessage(data.message);
        }
      });
    return () => {
      cable.subscriptions.remove(subscription);
    };
  }, []);

  const playSound = () => {
    document.getElementById('sound').play();
  }

  useEffect(() => {
    if (message === "Test Group On") {
      playSound();
      async function playNotify() {
        console.log(message);
        playSound();
      }
      const intervalId = setInterval(() => {
        playNotify();
      }, 5000)
      return () => clearInterval(intervalId);
    } else if ( message === 'Test Group Off' ) {
      console.log(message);
    }
  }, [message]);

  return (
    <div className={style.notificationContainer}>
     <img className={style.logo} src={'logo_name.png'} />
      <h2>Last Message Received</h2>
      <audio id='sound' src={'sound.mp3'} preload='auto'></audio>
      {message}
    </div>
  )
}

export default SoundNotifications;