import React, { useState, useEffect } from 'react'
import style from './UnvaccinatedEmployees.module.scss'

const UnvaccinatedEmployees = ({fluUnvaccinatedEmployees, covidUnvaccinatedEmployees}) => {
  return (
    <div className={style.container}>
      <h2>Unvaccinated Employees</h2>
      <div className={style.employeeContainers}>
        <div className={style.unvaccinatedFlu}>
          <h3 className={style.cardTitle}>Flu Vaccine</h3>
          <div className={style.unvaccinatedCard}>
          <div className={style.cardHeader}>
              <h5>Employee</h5>
              <h5>Status</h5>
            </div>
            { fluUnvaccinatedEmployees.map((employee) => {
              return (
                <div className={style.employee} key={employee.id}>
                  <p>{employee.full_name}</p>
                  <p>{employee.latest_flu_immunization ? employee.latest_flu_immunization.status : "No Record"}</p>
                </div>
              )}
            )}
          </div>
        </div>
        <div className={style.unvaccinatedCovid}>
          <h3 className={style.cardTitle}>Covid Vaccine</h3>
          <div className={style.unvaccinatedCard}>
            <div className={style.cardHeader}>
              <h5>Employee</h5>
              <h5>Status</h5>
            </div>
            { covidUnvaccinatedEmployees.map((employee) => {
              return (
                <div className={style.employee} key={employee.id}>
                  <p>{employee.full_name}</p>
                  <p>{employee.latest_covid_immunization ? employee.latest_covid_immunization.status : "No Record"}</p>
                </div>
              )}
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnvaccinatedEmployees