import React, { useEffect, useState } from 'react'
import axios from 'axios'
import ReactOnRails from 'react-on-rails'
import style from './AdminAccounts.module.scss'
import Select from 'react-select'
import FlashMessage from '../UtilityComponents/FlashMessage'

const AdminAccounts = () => {
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const [flashMessageVisible, setFlashMessageVisible] = useState(false)
  const [flashMessage, setFlashMessage] = useState('')

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await axios.get('/users')
      const users = response.data
      setUsers(users)
    }
    fetchUsers()
  }
  , [])

  const handleChange = async (e) => {
    const userId = e.value;
    setFlashMessageVisible(false)
    setSelectedUserId(userId);

    if (e.value !== 'none') {
      const response = await axios.get(`/users?user_id=${e.value}`);
      const user = response.data;
      setSelectedUser(user);
    } else {
      setSelectedUser('');
    }
  }

  const handleAccountTypeChange = async (e) => {
    const data = { user: selectedUser, role: e.value }
    const response = await axios.patch(`/update_role`, data, {
      headers: ReactOnRails.authenticityHeaders()
    });
    setSelectedUser(response.data)
    setFlashMessageVisible(true)
    setFlashMessage(`Role updated for ${selectedUser.first_name}`)
  }

  const handleAccountStatusChange = async (e) => {
    const data = { user: selectedUser, status: e.value }
    const response = await axios.patch(`/update_role`, data, {
      headers: ReactOnRails.authenticityHeaders()
    });
    setSelectedUser(response.data)
    setFlashMessageVisible(true)
    setFlashMessage(`Account status updated for ${selectedUser.first_name}`)
  }

  const handlePasswordReset = async () => {
    const data = { user: selectedUser }
    const response = await axios.post(`/password`, data, {
      headers: ReactOnRails.authenticityHeaders()
    });
    setFlashMessageVisible(true)
    setFlashMessage(`Password reset for ${selectedUser.first_name}`)
  }

  return (
    <div className={style.adminAccountsContainer}>
      {flashMessageVisible ? (<FlashMessage notice={flashMessage} />) : null}
      <div className={style.dropdown}>
        <Select
          className={style.dropdownBox}
          name="user"
          id="user"
          options={users.map((user) => { return { value: user.id, label: `${user.first_name} ${user.last_name}` } })}
          placeholder="Select User"
          onChange={handleChange}
        />
      </div>
    <div>
      { selectedUser ? (
          <div className={style.userInfo}>
            <p><b>Name</b></p>
            <p>{selectedUser.first_name} {selectedUser.last_name}</p>
            <b>Email</b>
            <p>{selectedUser.email}</p>
            <p><b>Role</b></p>
            <div>
              <Select
                className={style.accountTypeDropdown}
                name="userType"
                id="userType"
                options={[
                  { value: 'User', label: 'User' },
                  { value: 'Manager', label: 'Manager' },
                  { value: 'NurseManager', label: 'Nurse Manager'},
                  { value: 'Admin', label: 'Admin' },
                ]}
                value={{ value: selectedUser.role, label: selectedUser.role }}
                onChange={handleAccountTypeChange}
              />
            </div>
            <p><b>Account Status</b></p>
            <Select
                className={style.accountTypeDropdown}
                name="userStatus"
                id="userStatus"
                options={[
                  { value: 0, label: 'Inactive' },
                  { value: 1, label: 'Active' },
                ]}
                value={{ value: selectedUser.status, label: selectedUser.status}}
                onChange={handleAccountStatusChange}
              />
            <p><b>Reset Password</b></p>
            <p>The user will be receiving an email to reset their password.</p>
            <p>Please allow a few minutes for email to be sent and check spam folder.</p>
            <button className={style.resetButton} type="submit" onClick={handlePasswordReset}>Reset Password</button>
          </div> ) 
        : null
      }
    </div>
  </div>
  )
}

export default AdminAccounts;