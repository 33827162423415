import React, { useState } from 'react'
import style from './LoadingAnimation.module.scss'
import PulseLoader from "react-spinners/PulseLoader";

const LoadingAnimation = () => {
  const [loading, setLoading] = useState(true);

  return (
    <div className={style.loaderContainer}>
      <PulseLoader
        loading={loading}
        size={20} 
        color="#4a943e" />
      {/* <p>Finding your answer, hang tight!</p> */}
    </div>
  )
}

export default LoadingAnimation