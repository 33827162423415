import React, { useState, useEffect } from 'react'
import style from './HepBForm.module.scss'
import Select from 'react-select'
import ReactOnRails from 'react-on-rails'
import { DatePicker } from 'rsuite'
import axios from 'axios'

const HepBForm = ({employeeId, onFormSubmitSuccess, shotsCompleted}) => {
  const [statusSelection, setStatusSelection] = useState('')
  const [newShotVisible, setNewShotVisible] = useState('')
  const [dateAdministered, setDateAdministered] = useState('')
  const [declineDate, setDeclineDate] = useState('')
  const [receivedOnsite, setReceivedOnsite] = useState('')
  const [leadership, setLeadership] = useState([])
  const [hepBVaccines, setHepBVaccines] = useState([])
  const customStyles = {
    control: (base) => ({
      ...base,
      borderRadius: '6px',
      border: '1px solid #ddd',
      minWidth: '210px',
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: '14px',
      color: '#999'
    }),
    menu: (base) => ({
      ...base,
      fontSize: '14px',
      zIndex: '99',
      overflow: 'visible'
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: '14px',
    })
  }

  useEffect(() => {
    const fetchLeadership = async () => {
      const response = await axios.get('/employees', { 
        params: {
          filter: 'leadership'
        }
        })
      const leadership = response.data.employees
      setLeadership(leadership)
    }
    const fetchVaccines = async () => {
      const response = await axios.get('/hep_b_vaccines');
      const vaccines = response.data.vaccines
      setHepBVaccines(vaccines)
    }
    fetchLeadership()
    fetchVaccines()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const formObject = Object.fromEntries(formData.entries())
    const hepBImmunization = {}

    hepBImmunization.status = statusSelection
    hepBImmunization.employee_id = employeeId

    if(statusSelection === 1) {
      hepBImmunization.decline_reason = formObject.decline_reason
      hepBImmunization.decline_date = declineDate
    }
    const hepBShot = {}
    if(statusSelection === 0 && formObject.add_new_shot === 'true') {     
      hepBShot.received_onsite = receivedOnsite
      if(receivedOnsite) {
        hepBShot.administered_by_id = formObject.administered_by_id
        hepBShot.hep_b_vaccine_id = formObject.hep_b_vaccine_id
      }
      hepBShot.date_administered = dateAdministered
      hepBImmunization.hep_b_shots_attributes = hepBImmunization.hep_b_shots_attributes || [];
      hepBImmunization.hep_b_shots_attributes.push(hepBShot)
    }

    const response = await axios.post(`/hep_b_immunizations`, hepBImmunization, {
      headers: ReactOnRails.authenticityHeaders()
    })
    if (response.status === 200) {
      onFormSubmitSuccess()
    }
  }

  return (
    <div>
    { true && (
      <form onSubmit={handleSubmit}>
        <div className={style.title}>Hep B Shot</div>
        <div className={style.subTitle}>Vaccines Previously Completed: {shotsCompleted ? shotsCompleted : 0}</div>
        <div className={style.container}>
          <div className={style.formTable}>
            <label htmlFor="status">Status:
              <div className={style.subText}>
                (Select Pending if still receiving vaccinations)
              </div>
            </label>
            <Select
              styles={ customStyles }
              className={style.dropdownBox}
              name="status"
              id="status"
              options={
                [{value: 0, label: 'Pending'}, {value: 1, label: 'Declined'}, {value: 2, label: 'Completed/Received'}]
              }
              placeholder="Select a Status"
              onChange={(e) => {setStatusSelection(e?.value)}}
              required={true}
              isSearchable={false}
              menuPlacement='auto'
            />
            { statusSelection === 1 && (
              <>
                <label htmlFor="decline_date">Date Declined:</label>
                <DatePicker
                  className={style.datePicker}
                  name="decline_date"
                  id="decline_date"
                  format='MM/dd/yyy'
                  oneTap
                  onChange={(e) => setDeclineDate(e)}
                  placeholder="MM/DD/YYYY"
                  cleanable={true}
                  required={true}
                  placement='topEnd'
                />
                <label htmlFor="decline_reason">Reason for decline?</label>
                <Select
                  styles={ customStyles }
                  name="decline_reason"
                  id="decline_reason"
                  options={[
                    { value: 'Medical Contraindication', label: 'Medical Contraindication' },
                    { value: 'Offered but Declined', label: 'Offered but Declined' },
                    { value: 'Unknown/Other', label: 'Unknown/Other'}
                  ]}
                  placeholder="Select a Reason"
                  isSearchable={false}
                  menuPlacement='auto'
                  required={true}
                  isClearable={true}
                />
              </>
            )}
            { statusSelection === 0 && (
              <>
                <label htmlFor="add_new_shot">Add new vaccination?</label>
                <Select
                  styles={ customStyles }
                  className={style.dropdownBox}
                  name="add_new_shot"
                  id="add_new_shot"
                  options={
                    [{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
                  placeholder="Yes/No"
                  onChange={(e) => {setNewShotVisible(e?.value)}}
                  required={true}
                  isSearchable={false}
                  menuPlacement='auto'
                />
              </>
            )}
            { (newShotVisible) && (
              <>
                <label htmlFor="date_administered">Date Administered:</label>
                <DatePicker
                  name="date_administered"
                  id="date_administered"
                  className={style.datePicker}
                  onChange={(e) => setDateAdministered(e)}
                  oneTap
                  format="MM/dd/yyyy"
                  required={true}
                />
                <label htmlFor="received_onsite">Was the vaccine received onsite?</label>
                <Select
                  styles={ customStyles }
                  className={style.dropdownBox}
                  name="received_onsite"
                  id="received_onsite"
                  options={
                    [{value: true, label: 'Yes'}, {value: false, label: 'No'}]
                  }
                  placeholder="Yes/No"
                  onChange={(e) => {setReceivedOnsite(e.value)}}
                  required={true}
                  isSearchable={false}
                  menuPlacement='auto'
                />
                { receivedOnsite && (
                  <>
                    <label htmlFor="administered_by_id">Administered By:</label>
                    <Select
                      styles={ customStyles }
                      className={style.dropdownBox}
                      name="administered_by_id"
                      id="administered_by_id"
                      options={leadership.map((employee) => { return { value: employee.id, label: `${employee.full_name}` } })}
                      placeholder="Select Employee"
                      required={true}
                      isSearchable={false}
                      menuPlacement='auto'
                    />
                    <label htmlFor="hep_b_vaccine_id">Vaccine Given:</label>
                    <Select
                      styles={ customStyles }
                      className={style.dropdownBox}
                      name="hep_b_vaccine_id"
                      id="hep_b_vaccine_id"
                      options={hepBVaccines.map((vaccine) => { return { value: vaccine.id, label: `${vaccine.brand}` } })}
                      placeholder="Select a Vaccine"
                      required={true}
                      isSearchable={false}
                      menuPlacement='auto'
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className={style.buttonContainer}>
          <button className={style.addImmunizationButton} type="submit">Submit</button>
        </div>
      </form>
    )}
  </div>
  )
}

export default HepBForm