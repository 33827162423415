import React, { useState, useEffect } from 'react'
import style from './Immunization.module.scss'
import axios from 'axios'
import { set } from 'rsuite/esm/utils/dateUtils'

const Immunization = ({tb_screens, hep_b_immunizations}) => {
  const [hepBImmunizations, setHepBImmunizations] = useState(hep_b_immunizations)
  const [tbScreens, setTbScreens] = useState(tb_screens)

  return (
    <div className={style.container}>
      <h2>Upcoming Immunizations</h2>
      <div className={style.employeeContainers}>
        <div className={style.hepBImmunizations}>
          <h3 className={style.cardTitle}>HepB Status</h3>
          <div className={style.immunizationCard}>
            <div className={style.immunizationCardHeader}>
              <h5>Employee</h5>
              <h5>Shot #</h5>
              <h5>Due Date</h5>
            </div>
          { hepBImmunizations.map((immunization) => {
            return (
              <div className={style.employee} key={immunization.id}>
                <p>{immunization.employee.full_name}</p>
                <p>{immunization.shot_number_needed}</p>
                <p>{immunization.due_date}</p>
              </div>
            )})
          }
          </div>
        </div>
        <div className={style.TBImmunizations}>
          <h3 className={style.cardTitle}>TB X-rays</h3>
          <div className={style.immunizationCard}>
            <div className={style.immunizationCardHeader}>
              <h5>Employee</h5>
              <h5>Status</h5>
              <h5>Date</h5>
            </div>
          { tbScreens.map((immunization) => {
            return (
              <div className={style.employee} key={immunization.id}>
                <p>{immunization.employee.full_name}</p>
                { immunization.chest_xray_required ?
                  <>
                    <p>Xray</p>
                    <p>{immunization.chest_xray_due_date}</p>
                  </>
                  :
                  <>
                    <p>Test</p>
                    <p>{immunization.second_tb_test_due_date}</p>
                  </>
                }
              </div>
            )})
          }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Immunization