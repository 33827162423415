import ReactOnRails from 'react-on-rails';

import Welcome from '../bundles/App/components/PageLayout/Welcome';
import ContactList from '../bundles/App/components/Contacts/ContactList';
import MessagingBox from '../bundles/App/components/Messaging/MessagingBox';
import Ask from '../bundles/App/components/Ask/Ask';
import Login from '../bundles/App/components/Authentication/Login';
import HomePage from '../bundles/App/components/Home/HomePage';
import UpdatePassword from '../bundles/App/components/User/UpdatePassword';
import SoundNotifications from '../bundles/App/components/SoundNotifications/SoundNotifications';
import AdminSettings  from '../bundles/App/components/Admin/AdminSettings';
import Nursing from '../bundles/App/components/Nursing/Nursing';
import FlashMessage from '../bundles/App/components/UtilityComponents/FlashMessage';

// This is how react_on_rails can see the Welcome in the browser.
ReactOnRails.register({
  Welcome,
	ContactList,
	MessagingBox,
  Ask,
  Login,
  HomePage,
  UpdatePassword,
  SoundNotifications,
  AdminSettings,
  Nursing,
  FlashMessage
});
