import React from "react";
import { Button } from 'antd';
import axios from "axios";
import ReactOnRails from "react-on-rails";

const AuthButton = ({ type }) => {

  const handleClick = async () => {
    if (type === 'logout') {
      const response = await axios.delete('/logout', {
        headers: ReactOnRails.authenticityHeaders()
      })
      if (response.status === 200) {
        window.location.href = '/';
      }
    } else if (type === 'login') {
      window.location.href = '/login';
    }
  };

  if (type === 'hidden') {
    return null;
  }

  return (
    <Button 
      type='link' 
      size={'middle'} 
      onClick={handleClick}
      >
        {type === 'logout' ? 'Logout' : 'Login'}
    </Button>
  );
}

export default AuthButton;