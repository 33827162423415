import React, { useState, useEffect } from 'react'
import axios from 'axios'
import style from './CreateAccount.module.scss'
import Select from "react-select"
import { set } from 'rsuite/esm/utils/dateUtils'

const CreateAccount = () => {
  const selectOptions = [
    { value: 'User', label: 'User' },
    { value: 'Manager', label: 'Manager' },
    { value: 'Admin', label: 'Admin' },
    { value: 'NurseManager', label: 'Nurse Manager' }
  ]
  const [password, setPassword] = useState('');

  const getRandomByte = () => {
    if (window.crypto && window.crypto.getRandomValues) {
      const result = new Uint8Array(1);
      window.crypto.getRandomValues(result);
      return result[0];
    } else if (Crypto && Crypto.getRandomValues) {
      const result = new Uint8Array(1);
      Crypto.getRandomValues(result);

      return result[0];
    } else {
      return Math.floor(Math.random() * 256);
    }
  }

  const generatePassword = (length) => {
    const pattern = /[a-zA-Z0-9_\-\+\.]/;
    return Array.apply(null, { 'length': length })
      .map(function () {
        var result;
        while (true) {
          result = String.fromCharCode(getRandomByte());
          if (pattern.test(result)) {
            return result;
          }
        }
      }, this)
      .join('');
  }

  useEffect (() => {
    setPassword(generatePassword(16));
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault(); 
    const formData = new FormData(e.target);
    const data = {}
    data.user = Object.fromEntries(formData);
    try {
      const response = await axios.post(`/signup`, data, {
        headers: ReactOnRails.authenticityHeaders()
      });
      if (response.data.message === 'User created') {
        alert('Account created successfully');
      }
      e.target.reset();
    } catch (error) {
      console.error(error);
      alert(error.response.data.error);
      e.target.reset();
    }
  }

  return (
    <>
      <form className={style.formContainer} onSubmit={handleSubmit}>
        <h2>Create a New User</h2>
        <label htmlFor="first_name"><b>First Name:</b></label>
        <input type="text" id="first_name" name="first_name" />
        <label htmlFor="last_name"><b>Last Name:</b></label>
        <input type="text" id="last_name" name="last_name" />
        <label htmlFor="email"><b>Email:</b></label>
        <input type="email" id="email" name="email" />
        <label htmlFor="role"><b>Role:</b></label>
        <input type='hidden' id="password" name="password" value={password} />
        <input type='hidden' id="password_confirmation" name="password_confirmation" value={password} />
        <Select
          className={style.dropdownBox}
          name="role"
          id="role"
          options={selectOptions}
          placeholder="Select Role"
        />
        <div>
          <p><b>User</b> will only have access to Ask.</p>
          <p><b>Manager</b> gets access to Messages.</p>
          <p><b>Manager</b> gets access to Messages.</p>
          <p><b>Admin</b> gets access to all, including creating and updating accounts.</p>
        </div>
        <button type="submit" value="Submit">Create Account</button>
        <p><b>Note:</b> User will receive an email to sign up after creating an account.</p>
      </form>
    </>
  )
}

export default CreateAccount;
