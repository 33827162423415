import React, { useEffect, useState } from 'react'
import axios from 'axios'
import style from './CreateEmployee.module.scss'
import Select from "react-select"
import ManageEmployees from './ManageEmployees'
import Modal from '../Modal'
import TbScreen from './TbScreen'
import { DatePicker, Button } from 'rsuite'
import "rsuite/dist/rsuite-no-reset.min.css";

const CreateUpdateEmployeeForm = ({employee, onUpdateEmployee}) => {
  const [firstName, setFirstName] = useState(employee?.first_name || '');
  const [preferredName, setPreferredName] = useState(employee?.preferred_name || '');
  const [lastName, setLastName] = useState(employee?.last_name || '');
  const [email, setEmail] = useState(employee?.email || '');
  const [phoneNumber, setPhoneNumber] = useState(employee?.phone_number || '');
  const [leadership, setLeadership] = useState([])
  const [employeeCreated, setEmployeeCreated] = useState(false)
  const [createdEmployee, setCreatedEmployee] = useState(null)
  const [employeeType, setEmployeeType] = useState(null)
  const [employeeInfoFormVisible, setEmployeeInfoFormVisible] = useState(true)
  const [vaccinationModalVisible, setVaccinationModalVisible] = useState(false)
  const [vaccinationFormVisible, setVaccinationFormVisible] = useState(false)
  const [tbScreenFormVisible, setTbScreenFormVisible] = useState(false)
  const [newEmployeeInfo, setNewEmployeeInfo] = useState({})
  const [covidStatusSelection, setCovidStatusSelection] = useState(false)
  const [fluStatusSelection, setFluStatusSelection] = useState(false)
  const [covidVaccines, setCovidVaccines] = useState([])
  const [fluVaccines, setFluVaccines] = useState([])
  const [fluReceivedOnsite, setFluReceivedOnsite] = useState(false)
  const [covidReceivedOnsite, setCovidReceivedOnsite] = useState(false)
  const [covidImmunizationDate, setCovidImmunizationDate] = useState('')
  const [fluImmunizationDate, setFluImmunizationDate] = useState('')
  const [covidImmunizationDeclineDate, setCovidImmunizationDeclineDate] = useState('')
  const [fluImmunizationDeclineDate, setFluImmunizationDeclineDate] = useState('')
  const customStyles = {
    control: (base) => ({
      ...base,
      borderRadius: '6px',
      border: '1px solid #ddd',
      minWidth: '210px',
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: '14px',
      color: '#999'
    }),
    menu: (base) => ({
      ...base,
      fontSize: '14px',
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: '14px',
    })
  }

  const departments = [
    { value: 'Administrative', label: 'Administrative' },
    { value: 'Maintenance', label: 'Maintenance' },
    { value: 'Dietary', label: 'Dietary' },
    { value: 'Nursing', label: 'Nursing' },
    { value: 'Activities', label: 'Activities' },
    { value: 'EVS', label: 'EVS' },
    { value: 'Social Workers', label: 'Social Workers' },
    { value: 'Therapy', label: 'Therapy' },
  ]

 const positions = [
    { value: 'Administrator', label: 'Administrator' },
    { value: 'Director of Maintenance', label: 'Director of Maintenance' },
    { value: 'Dietary Manager', label: 'Dietary Manager' },
    { value: 'Central Supply Clerk/EVS Director', label: 'Central Supply Clerk/EVS Director' },
    { value: 'Activities Director/Transportation Director', label: 'Activities Director/Transportation Director' },
    { value: 'Assistant to the Administrator', label: 'Assistant to the Administrator' },
    { value: 'IT Director', label: 'IT Director' },
    { value: 'Social Worker', label: 'Social Worker' },
    { value: 'Assistant Maintenance Director', label: 'Assistant Maintenance Director' },
    { value: 'Social Services Director', label: 'Social Services Director' },
    { value: 'ADON/Infection Control Preventionist/Staff Development', label: 'ADON/Infection Control Preventionist/Staff Development' },
    { value: 'Director of Nursing', label: 'Director of Nursing' },
    { value: 'Unit Manager', label: 'Unit Manager' },
    { value: 'COO/Business Office Director', label: 'COO/Business Office Director' },
    { value: 'RN', label: 'RN' },
    { value: 'CNA', label: 'CNA' },
    { value: 'Medical Records', label: 'Medical Records' },
    { value: 'Dietary Aide', label: 'Dietary Aide' },
    { value: 'LPN', label: 'LPN' },
    { value: 'Housekeeping', label: 'Housekeeping' },
    { value: 'Activities Aide', label: 'Activities Aide' },
    { value: 'Restorative CNA', label: 'Restorative CNA' },
    { value: 'Laundry', label: 'Laundry' },
    { value: 'Cook', label: 'Cook' },
    { value: 'Housekeeping/Laundry', label: 'Housekeeping/Laundry' },
    { value: 'Nurse Assistant', label: 'Nurse Assistant' },
    { value: 'Restorative', label: 'Restorative' },
    { value: 'Activites Aide', label: 'Activites Aide' },
    { value: 'Payroll', label: 'Payroll' },
    { value: 'Speech Therapist', label: 'Speech Therapist' },
    { value: 'Occupational Therapist', label: 'Occupational Therapist' },
    { value: 'Physical Therapist', label: 'Physical Therapist' },
    { value: 'Director of Rehabilitation', label: 'Director of Rehabilitation' },
    { value: 'Physical Therapy Assistant', label: 'Physical Therapy Assistant' },
    { value: 'Occupational Therapy Assistant', label: 'Occupational Therapy Assistant' },
  ]

  let defaultEmployeeTypeValue;
  if (employee) {
    defaultEmployeeTypeValue = {value: employee.employee_type, label: employee.employee_type}
  } else {
    defaultEmployeeTypeValue = undefined
  }

  let defaultLeadershipValue;
  if (employee) {
    defaultLeadershipValue = employee.leadership ? {value: 'true', label: 'Yes'} : {value: 'false', label: 'No'}
  } else {
    defaultLeadershipValue = undefined
  }

  let defaultDepartmentValue;
  if (employee) {
    defaultDepartmentValue = {value: employee.department, label: employee.department}
  } else {
    defaultDepartmentValue = undefined
  }

  let defaultPositionValue;
  if (employee) {
    defaultPositionValue = {value: employee.position, label: employee.position}
  } else {
    defaultPositionValue = undefined
  }

  let defaultSupervisorValue;
  if (employee) {
    defaultSupervisorValue = {value: employee.supervisor_id, label: employee.supervisor_name}
  } else {
    defaultSupervisorValue = undefined
  }

  useEffect(() => {
    const fetchLeadership = async () => {
      const response = await axios.get('/employees', {
        params: {filter: "leadership"}
      })
      const managers = response.data.employees
      setLeadership(managers)
    }
    fetchLeadership()
    }, []
  )

  useEffect(() => {
    const fetchCovidVaccines = async () => {
      try {
        const response = await axios.get(`/covid_vaccines`);
        const covidVaccines = response.data.vaccines;
        setCovidVaccines(covidVaccines);
      } catch (error) {
        console.log("There was an error fetching Covid Vaccines")
      }
    };
  
    const fetchFluVaccines = async () => {
      try {
        const response = await axios.get(`/flu_vaccines`);
        const fluVaccines = response.data.vaccines;
        setFluVaccines(fluVaccines);
      } catch (error) {
        console.log("There was an error fetching Flu Vaccines")
      }
    };
  
    fetchCovidVaccines();
    fetchFluVaccines();
  }, []);

  const handleEmployeeInfoForm = (e) => {
    e.preventDefault();

    const info = {
      first_name: e.target.elements.first_name.value,
      last_name: e.target.elements.last_name.value,
      email: e.target.elements.email.value,
      phone_number: e.target.elements.phone_number.value,
      employee_type: e.target.elements.employee_type.value,
    }

    if (e.target.elements.employee_type.value === "Payroll") {
      info.leadership = e.target.elements.leadership.value,
      info.department = e.target.elements.department.value,
      info.position = e.target.elements.position.value,
      info.supervisor_id = e.target.elements.supervisor_id.value
    }

    if (e.target.elements.employee_type.value === "0") {
        info.leadership = e.target.elements.leadership.value,
        info.department = e.target.elements.department.value,
        info.position = e.target.elements.position.value,
        info.supervisor_id = e.target.elements.supervisor_id.value
    }

    setNewEmployeeInfo(info);
    setEmployeeInfoFormVisible(false);
    if (employee) {
      handleUpdateEmployee(info);
    } else {
      setVaccinationModalVisible(true);
    }
  }

  const handleCovidFluVaccineForm = (e) => {
    e.preventDefault();
    const data = newEmployeeInfo;

    const form = new FormData(e.target);

    if (form.get('covid_immunization.status') != '') {
      const covidImmunization = {
        status: form.get('covid_immunization.status'),
        date_administered: covidImmunizationDate,
        decline_reason: form.get('covid_immunization.decline_reason'),
        decline_date: covidImmunizationDeclineDate,
        administered_by_id: form.get('covid_immunization.administered_by_id'),
        covid_vaccine_id: form.get('covid_immunization.covid_vaccine_id'),
        received_onsite: form.get('covid_immunization.received_onsite')
      }
      data.covid_immunizations_attributes = [covidImmunization];
    }
    if (form.get('flu_immunization.status') != '') {
      const fluImmunization = {
        status: form.get('flu_immunization.status'),
        date_administered: fluImmunizationDate,
        decline_reason: form.get('flu_immunization.decline_reason'),
        decline_date: fluImmunizationDeclineDate,
        administered_by_id: form.get('flu_immunization.administered_by_id'),
        flu_vaccine_id: form.get('flu_immunization.flu_vaccine_id'),
        received_onsite: form.get('flu_immunization.received_onsite')
      }
      data.flu_immunizations_attributes = [fluImmunization];
    }
    setNewEmployeeInfo(data);
    setVaccinationFormVisible(false);
    setTbScreenFormVisible(true);
  }

  const handleTbScreen = (tbScreenForm) => {
    const data = newEmployeeInfo;

    const tbScreen = {
      vaccinated: tbScreenForm.get('tb_screen.vaccinated'),
      chest_xray_date: tbScreenForm.get('last_xray'),
      chest_xray_result: tbScreenForm.get('last_xray_result'),
    }

    data.tb_screen_attributes = tbScreen;
    data.tb_screen_attributes.tb_tests_attributes = [];

    if (tbScreenForm.get('positive_tb_skin_test') === 'true') {
      const oldTbTest = {
        result: 'positive',
        date_administered: tbScreenForm.get('date_of_positive'),
        induration: tbScreenForm.get('induration'),
      }
      data.tb_screen_attributes.tb_tests_attributes.push(oldTbTest);
    }

    if (tbScreenForm.get('add_tb_test') === 'true') {
      const newTbTest = {}
      newTbTest.received_onsite = tbScreenForm.get('tb_screen.tb_tests.received_onsite')
      if (tbScreenForm.get('tb_screen.tb_tests.received_onsite') === 'true') {
        newTbTest.site = tbScreenForm.get('tb_screen.tb_tests.site'),
        newTbTest.induration = tbScreenForm.get('tb_screen.tb_tests.induration'),
        newTbTest.administered_by_id = tbScreenForm.get('tb_screen.tb_tests.administered_by_id'),
        newTbTest.tb_ppd_id = tbScreenForm.get('tb_screen.tb_tests.tb_ppd_id')
      } else if (tbScreenForm.get('tb_screen.tb_tests.received_onsite') === 'false') {
        newTbTest.test_type = tbScreenForm.get('tb_screen.tb_tests.test_type')
        newTbTest.blood_test_brand = tbScreenForm.get('tb_screen.tb_tests.blood_test_brand')
      }
      newTbTest.date_administered = tbScreenForm.get('tb_screen.tb_tests.date_administered'),
      newTbTest.date_evaluated = tbScreenForm.get('tb_screen.tb_tests.date_evaluated'),
      newTbTest.result = tbScreenForm.get('tb_screen.tb_tests.result')

      data.tb_screen_attributes.tb_tests_attributes.push(newTbTest);
    }

    setNewEmployeeInfo(data);
    setTbScreenFormVisible(false);
    handleCreateEmployee();
  }


  const handleCreateEmployee = async () => {
    const data = newEmployeeInfo;
    try {
      const response = await axios.post(`/employees`, data, {
        headers: ReactOnRails.authenticityHeaders()
      });
      if (response.status === 200) {
        alert('Employee created successfully');
        setCreatedEmployee(response.data);
        setEmployeeCreated(true);
      }
    } catch (error) {
      console.error(error);
      alert(error.response.data.error);
    }
  }

  const handleUpdateEmployee = async (data) => {
    try {
      const response = await axios.put(`/employees/${employee.id}`, data, {
        headers: ReactOnRails.authenticityHeaders()
      });
      if (response.status === 200) {
        onUpdateEmployee(response.data, response.status);
      }
    } catch (error) {
      console.error(error);
      onUpdateEmployee(null, error.response.data.error);
    }
  }

  const hideModal = () => {
    setVaccinationModalVisible(false);
  }

  const showVaccinationForm = () => {
    setVaccinationModalVisible(false);
    setVaccinationFormVisible(true);
  }

  if (employeeCreated) {
    return <ManageEmployees employee={createdEmployee}/>
  } else {
    return (
      <>
        { employeeInfoFormVisible && (
          <form className={style.createUpdateForm} onSubmit={handleEmployeeInfoForm}>
            <div className={`${ employee ? style.employeeInfoUpdate : style.employeeInfo}`}>
              <h2>{ employee ? 'Update Employee' : 'New Employee' }</h2>
              <label htmlFor="first_name"><b>First Name:</b></label>
              <input type="text" id="first_name" name="first_name" defaultValue={firstName} required={true}/>
              <label htmlFor="preferred_name"><b>Preferred Name:</b></label>
              <input type="text" id="preferred_name" name="preferred_name" defaultValue={preferredName}/>
              <label htmlFor="last_name"><b>Last Name:</b></label>
              <input type="text" id="last_name" name="last_name" defaultValue={lastName} required={true}/>
              <label htmlFor="email"><b>Email:</b></label>
              <input type="email" id="email" name="email" defaultValue={email} required={true}/>
              <label htmlFor="phone_number"><b>Phone Number:</b></label>
              <input type="tel" id="phone_number" name="phone_number" defaultValue={phoneNumber} required={true}/>
              <label htmlFor="employee_type"><b>Employee Type:</b></label>
              <Select
                styles={ customStyles}
                className={style.dropdownBox}
                name="employee_type"
                id="employee_type"
                options={[
                    {value: 'Payroll', label: 'Payroll'},
                    {value: 'Non-Emp Licensed', label: 'Non-Emp Licensed'},
                    {value: 'Other Contract', label: 'Other Contract'},
                    {value: 'Adult/Volunteer', label: 'Adult/Volunteer'}
                  ]}
                placeholder="Select Employee Type"
                defaultValue= { defaultEmployeeTypeValue }
                onChange={(e) => {setEmployeeType(e.value)}}
                required={true}
              />
              <br />
              { (employeeType === "Payroll" || employee?.employee_type === 'Payroll') ? (
                <div>
                  <label htmlFor="leadership"><b>Leadership:</b></label>
                  <Select
                    styles={ customStyles }
                    className={style.dropdownBox}
                    name="leadership"
                    id="leadership"
                    options={
                      [{value: 'true', label: 'Yes'}, {value: 'false', label: 'No'}]}
                    placeholder="Yes/No"
                    defaultValue= { defaultLeadershipValue }
                    required={true}
                  />
                  <br />
                  <label htmlFor="department"><b>Department:</b></label>
                  <Select
                    styles={ customStyles }
                    className={style.dropdownBox}
                    name="department"
                    id="department"
                    options={departments}
                    placeholder="Select Department"
                    defaultValue = { defaultDepartmentValue }
                    required={true}
                  />
                  <br />
                  <label htmlFor="position"><b>Position:</b></label>
                  <Select
                    styles={ customStyles }
                    className={style.dropdownBox}
                    name="position"
                    id="position"
                    options={positions}
                    placeholder="Select Position"
                    defaultValue = { defaultPositionValue }
                    required={true}
                  />
                  <br />
                  <label htmlFor="supervisor_id"><b>Supervisor:</b></label>
                  <Select
                    styles={ customStyles }
                    className={style.dropdownBox}
                    name="supervisor_id"
                    id="supervisor_id"
                    options={leadership.map((employee) => { return { value: employee.id, label: `${employee.full_name}` } })}
                    placeholder="Select Supervisor"
                    defaultValue = { defaultSupervisorValue }
                  />
                  <br />
                </div>
              ) : null }
              { employee ? (
                <button className={style.defaultButton} type="submit" value="submit">Update</button>
              ) : (
                <button className={style.defaultButton} type="submit" value="submit">Next</button>
              )}
            </div>
          </form>
        )}
        { vaccinationModalVisible && !employee ? (
          <div className={style.createUpdateForm}>
            <div className={style.vaccinationInfoModal}>
              <Modal closeModal={hideModal} showCloseButton={false}>
                <h2 className={style.modalTitle}>Employee Vaccination Info</h2>
                <p>Would you like to add employee vaccination information now?</p>
                <p>Yes to Continue / No to add later</p>
                <button className={style.defaultButton} type="button" onClick={showVaccinationForm}>Yes</button>
                <button className={style.defaultButton} type="button" value="submit" onClick={handleCreateEmployee}>No, Add Later</button>
              </Modal>
            </div>
          </div>
        ): null}
        { vaccinationFormVisible && (
          <form onSubmit={handleCovidFluVaccineForm}>
            <div className={style.immunizationForms}>
              <div className={style.covidImmunizationForm}>
                <h2>COVID-19 Immunization:</h2>
                <label htmlFor="covid_immunization.status"><b><p>Status:</p></b></label>
                <Select
                  styles={ customStyles }
                  name="covid_immunization.status"
                  id="covid_immunization.status"
                  options={[
                    { value: 'received', label: 'Received' },
                    { value: 'declined', label: 'Declined'}
                  ]}
                  onChange={(e) => { 
                    e === null ? setCovidStatusSelection(false) : setCovidStatusSelection(e.value)
                  }}
                  placeholder="Select Status"
                  isClearable={true}
                  isSearchable={false}
                />
                { covidStatusSelection === 'received' && (
                  <>
                    <label htmlFor="covid_immunization.date_administered"><b><p>Date Received:</p></b></label>
                    < DatePicker
                      className={style.datePicker}
                      name="covid_immunization.date_administered"
                      id="covid_immunization.date_administered"
                      format='MM/dd/yyy'
                      oneTap
                      onChange={(e) => setCovidImmunizationDate(e)}
                      placeholder="MM/DD/YYYY"
                      cleanable={true}
                      required={true}
                    />
                    <label htmlFor="covid_immunization.received_onsite"><b><p>Received On Site?</p></b></label>
                    <Select
                      styles={ customStyles }
                      className={style.dropdownBox}
                      name="covid_immunization.received_onsite"
                      id="covid_immunization.received_onsite"
                      options={
                        [{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
                      placeholder="Yes/No"
                      onChange={(e) => {setCovidReceivedOnsite(e.value)}}
                      required={true}
                      isSearchable={false}
                    />
                    { covidReceivedOnsite === true && (
                      <>                              
                        <label htmlFor="covid_immunization.administered_by_id"><b><p>Who administered?</p></b></label>
                        <Select
                          styles={ customStyles }
                          className={style.dropdownBox}
                          name="covid_immunization.administered_by_id"
                          id="covid_immunization.administered_by_id"
                          options={leadership.map((employee) => { return { value: employee.id, label: `${employee.full_name}` } })}
                          placeholder="Select Employee"
                          required={true}
                          isSearchable={false}
                        />
                        <label htmlFor="covid_immunization.covid_vaccine_id"><b><p>Which vaccine was administered?</p></b></label>
                        <Select
                          styles={ customStyles }
                          className={style.dropdownBox}
                          name="covid_immunization.covid_vaccine_id"
                          id="covid_immunization.covid_vaccine_id"
                          options={covidVaccines.map((vaccine) => { return { value: vaccine.id, label: `${vaccine.brand}` } })}
                          placeholder="Select Vaccine"
                          required={true}
                          isSearchable={false}
                        />
                      </>
                    )}
                  </>
                )}
                { covidStatusSelection === 'declined' && (
                  <>
                    <label htmlFor="covid_immunization.decline_date"><b><p>Date Declined:</p></b></label>
                    < DatePicker
                      className={style.datePicker}
                      name="covid_immunization.decline_date"
                      id="covid_immunization.decline_date"
                      format='MM/dd/yyy'
                      oneTap
                      onChange={(e) => setCovidImmunizationDeclineDate(e)}
                      placeholder="MM/DD/YYYY"
                      cleanable={true}
                      required={true}
                    />
                    <label htmlFor="covid_immunization.decline_reason"><b><p>Reason for Decline:</p></b></label>
                    <Select
                      styles={ customStyles }
                      name="covid_immunization.decline_reason"
                      id="covid_immunization.decline_reason"
                      options={[
                        { value: 'Medical Contraindication', label: 'Medical Contraindication' },
                        { value: 'Offered but Declined', label: 'Offered but Declined' },
                        { value: 'Unknown/Other', label: 'Unknown/Other'}
                      ]}
                      placeholder="Select a Reason"
                      isSearchable={false}
                      required={true}
                    />
                  </>
                )}
              </div>
              <div className={style.fluImmunizationForm}>
                  <h2>Influenza Immunization:</h2>
                  <label htmlFor="flu_immunization.status"><b><p>Status:</p></b></label>
                  <Select
                    styles={ customStyles }
                    name="flu_immunization.status"
                    id="flu_immunization.status"
                    options={[
                      { value: 'received', label: 'Received' },
                      { value: 'declined', label: 'Declined'}
                    ]}
                    placeholder="Select Status"
                    onChange={(e) => {
                      e === null ? setFluStatusSelection(false) : setFluStatusSelection(e.value)
                    }}
                    isClearable={true}
                    isSearchable={false}
                  />
                  { fluStatusSelection === 'received' && (
                    <>
                      <label htmlFor="flu_immunization.date_administered"><b><p>Date Received:</p></b></label>
                      < DatePicker
                        className={style.datePicker}
                        name="flu_immunization.date_administered"
                        id="flu_immunization.date_administered"
                        format='MM/dd/yyy'
                        oneTap
                        onChange={(e) => setFluImmunizationDate(e)}
                        placeholder="MM/DD/YYYY"
                        cleanable={true}
                        required={true}
                      />
                      <label htmlFor="flu_immunization.received_onsite"><b><p>Received On Site?</p></b></label>
                      <Select
                        styles={ customStyles }
                        className={style.dropdownBox}
                        name="flu_immunization.received_onsite"
                        id="flu_immunization.received_onsite"
                        options={
                          [{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
                        placeholder="Yes/No"
                        onChange={(e) => {setFluReceivedOnsite(e.value)}}
                        required={true}
                        isSearchable={false}
                      />
                      { fluReceivedOnsite === true && (
                        <>                              
                          <label htmlFor="flu_immunization.administered_by_id"><b><p>Who administered?</p></b></label>
                          <Select
                            styles={ customStyles }
                            className={style.dropdownBox}
                            name="flu_immunization.administered_by_id"
                            id="flu_immunization.administered_by_id"
                            options={leadership.map((employee) => { return { value: employee.id, label: `${employee.full_name}` } })}
                            placeholder="Select Employee"
                            required={true}
                            isSearchable={false}
                          />
                          <label htmlFor="flu_immunization.flu_vaccine_id"><b><p>Which vaccine was administered?</p></b></label>
                          <Select
                            styles={ customStyles }
                            className={style.dropdownBox}
                            name="flu_immunization.flu_vaccine_id"
                            id="flu_immunization.flu_vaccine_id"
                            options={fluVaccines.map((vaccine) => { return { value: vaccine.id, label: `${vaccine.brand}` } })}
                            placeholder="Select Vaccine"
                            required={true}
                            isSearchable={false}
                          />
                        </>
                      )}
                    </>
                  )}
                  { fluStatusSelection === 'declined' && (
                    <>
                    <label htmlFor="flu_immunization.decline_date"><b><p>Date Declined:</p></b></label>
                    < DatePicker
                      className={style.datePicker}
                      name="flu_immunization.decline_date"
                      id="flu_immunization.decline_date"
                      format='MM/dd/yyy'
                      oneTap
                      onChange={(e) => setFluImmunizationDeclineDate(e)}
                      placeholder="MM/DD/YYYY"
                      cleanable={true}
                      required={true}
                    />
                      <label htmlFor="flu_immunization.decline_reason"><b><p>Reason for Decline:</p></b></label>
                      <Select
                        styles={ customStyles }
                        name="flu_immunization.decline_reason"
                        id="flu_immunization.decline_reason"
                        options={[
                          { value: 'Medical Contraindication', label: 'Medical Contraindication' },
                          { value: 'Offered but Declined', label: 'Offered but Declined' },
                          { value: 'Unknown/Other', label: 'Unknown/Other'}
                        ]}
                        placeholder="Select a Reason"
                        required={true}
                      />
                    </>
                  )}
              </div>
              <div className={style.immunizationSubmitButton}>
                <button className={style.defaultButton} type="submit" value="Submit">Next</button>
              </div>
            </div>
          </form>
        )}
        { tbScreenFormVisible && (
          <div>
            <TbScreen nurses={leadership} onUpdateTbScreen={handleTbScreen}/>
          </div>
        )}
      </>
    )
  }
}

export default CreateUpdateEmployeeForm;
