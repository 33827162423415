import React, { useState, useEffect } from 'react'
import ReactOnRails from 'react-on-rails'
import style from './ImmunizationMetrics.module.scss'
import axios from 'axios'
import { Select } from 'react-select'
import { set } from 'rsuite/esm/utils/dateUtils'

const ImmunizationMetrics = () => {
  const [payrollCounts, setPayrollCounts] = useState(null)
  const [nonEmpLicensedCounts, setNonEmpLicensedCounts] = useState(null)
  const [adultVolunteerCounts, setAdultVolunteerCounts] = useState(null)
  const [otherContractCounts, setOtherContractCounts] = useState(null)
  const [missingEmployeeNames, setMissingEmployeeNames] = useState([])
  const customStyles = {
    control: (base) => ({
      ...base,
      borderRadius: '6px',
      border: '1px solid #ddd',
      minWidth: '210px',
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: '14px',
      color: '#999'
    }),
    menu: (base) => ({
      ...base,
      fontSize: '14px',
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: '14px',
    })
  }

  const getMissingEmployees = async () => {
    if (missingEmployeeNames.length > 0) {
      setMissingEmployeeNames([])
      return
    } else {
      const response = await axios.get('/nursing/missing_covid_immunizations', {
        headers: ReactOnRails.authenticityHeaders(),
      });
      setMissingEmployeeNames(response.data.employees)
    }
  }

  const missingEmployeeCount = () => {
    const payrollTotals = (payrollCounts?.covid_up_to_date || 0) + (payrollCounts?.covid_declined?.medical_contraindication || 0) + (payrollCounts?.covid_declined?.offered_but_declined || 0) + (payrollCounts?.covid_declined?.unknown_other || 0)

    const nonEmpLicensedTotals = (nonEmpLicensedCounts?.covid_up_to_date || 0) + (nonEmpLicensedCounts?.covid_declined?.medical_contraindication || 0) + (nonEmpLicensedCounts?.covid_declined?.offered_but_declined || 0) + (nonEmpLicensedCounts?.covid_declined?.unknown_other || 0)
    
    const adultVolunteerTotals = (adultVolunteerCounts?.covid_up_to_date || 0) + (adultVolunteerCounts?.covid_declined?.medical_contraindication || 0) + (adultVolunteerCounts?.covid_declined?.offered_but_declined || 0) + (adultVolunteerCounts?.covid_declined?.unknown_other || 0)
    
    const otherContractTotals = (otherContractCounts?.covid_up_to_date || 0) + (otherContractCounts?.covid_declined?.medical_contraindication || 0) + (otherContractCounts?.covid_declined?.offered_but_declined || 0) + (otherContractCounts?.covid_declined?.unknown_other || 0)

    return (payrollCounts?.total || 0) + (nonEmpLicensedCounts?.total || 0) + (adultVolunteerCounts?.total || 0) + (otherContractCounts?.total || 0) - (payrollTotals + nonEmpLicensedTotals + adultVolunteerTotals + otherContractTotals)
  }

  useEffect(() => {
    const fetchActiveEmployees = async () => {
      const response = await axios.get('/nursing/metrics', {
        headers: ReactOnRails.authenticityHeaders(),
      });
      setPayrollCounts(response.data.payroll)
      setNonEmpLicensedCounts(response.data.non_emp_licensed)
      setAdultVolunteerCounts(response.data.adult_volunteer)
      setOtherContractCounts(response.data.other_contract)
    }
    fetchActiveEmployees();
  }, [])

  return (
    <div className={style.mainContainer}>
      <div className={style.title}>
        Immunization Metrics
      </div>
      <div className={style.infoTable}>
        <>
          <div className={style.headerRow}>COVID-19</div>
          <div className={style.headerRow}>*All Core HCP</div>
          <div className={style.headerRow}>*All HCP</div>
          <div className={style.headerRow}>*Employees (staff on facility payroll)</div>
          <div className={style.headerRow}>*Licensed independent practitioners: Physicians, advanced practice nurses, & physician assistants</div>
          <div className={style.headerRow}>Adult students/trainees & volunteers</div>
          <div className={style.headerRow}>Other Contract Personnel</div>
        </>
        <>
          <div className={style.questionRow}>1. *Number of HCP that were eligible to have worked at this healthcare facility for at least 1 day during the week of data collection.</div>
          <div className={style.questionRow}></div>
          <div className={style.questionRow}></div>
          <div className={`${style.questionRow} ${style.totals}`}>{payrollCounts?.total}</div>
          <div className={`${style.questionRow} ${style.totals}`}>{nonEmpLicensedCounts?.total}</div>
          <div className={`${style.questionRow} ${style.totals}`}>{adultVolunteerCounts?.total}</div>
          <div className={`${style.questionRow} ${style.totals}`}>{otherContractCounts?.total}</div>
        </>
        <>
          <div className={style.questionRow}>2. * Cumulative number of HCP in Question #1 who are up to date with COVID-19 vaccine(s).<br />Please review the current definition of up to date.</div>
          <div className={style.questionRow}></div>
          <div className={style.questionRow}></div>
          <div className={`${style.questionRow} ${style.totals}`}>{payrollCounts?.covid_up_to_date}</div>
          <div className={`${style.questionRow} ${style.totals}`}>{nonEmpLicensedCounts?.covid_up_to_date}</div>
          <div className={`${style.questionRow} ${style.totals}`}>{adultVolunteerCounts?.covid_up_to_date}</div>
          <div className={`${style.questionRow} ${style.totals}`}>{otherContractCounts?.covid_up_to_date}</div>
        </>
        <>
          <div className={style.questionRow}>3. * Cumulative number of HCP in Question #1 with other conditions.</div>
          <div className={style}></div>
          <div className={style}></div>
          <div className={style}></div>
          <div className={style}></div>
          <div className={style}></div>
          <div className={style}></div>
        </>
        <>
          <div className={style.questionRow}>3.1 * Medical contraindication to COVID-19 vaccine.</div>
          <div className={style.questionRow}></div>
          <div className={style.questionRow}></div>
          <div className={`${style.questionRow} ${style.totals}`}>{payrollCounts?.covid_declined.medical_contraindication}</div>
          <div className={`${style.questionRow} ${style.totals}`}>{nonEmpLicensedCounts?.covid_declined.medical_contraindication}</div>
          <div className={`${style.questionRow} ${style.totals}`}>{adultVolunteerCounts?.covid_declined.medical_contraindication}</div>
          <div className={`${style.questionRow} ${style.totals}`}>{otherContractCounts?.covid_declined.medical_contraindication}</div>
        </>
        <>
          <div className={style.questionRow}>3.2 * Offered but declined COVID-19 vaccine.</div>
          <div className={style.questionRow}></div>
          <div className={style.questionRow}></div>
          <div className={`${style.questionRow} ${style.totals}`}>{payrollCounts?.covid_declined.offered_but_declined}</div>
          <div className={`${style.questionRow} ${style.totals}`}>{nonEmpLicensedCounts?.covid_declined.offered_but_declined}</div>
          <div className={`${style.questionRow} ${style.totals}`}>{adultVolunteerCounts?.covid_declined.offered_but_declined}</div>
          <div className={style.questionRow}>{otherContractCounts?.covid_declined.offered_but_declined}</div>
        </>
        <>
          <div className={style.questionRow}>3.3 * Unknown/Other Covid-19 vaccination status.</div>
          <div className={style.questionRow}></div>
          <div className={style.questionRow}></div>
          <div className={`${style.questionRow} ${style.totals}`}>{payrollCounts?.covid_declined.unknown_other}</div>
          <div className={`${style.questionRow} ${style.totals}`}>{nonEmpLicensedCounts?.covid_declined.unknown_other}</div>
          <div className={`${style.questionRow} ${style.totals}`}>{adultVolunteerCounts?.covid_declined.unknown_other}</div>
          <div className={`${style.questionRow} ${style.totals}`}>{otherContractCounts?.covid_declined.unknown_other}</div>
        </>
      </div>
      <div className={style.footerNote}>
        * There are a total of {payrollCounts?.total + nonEmpLicensedCounts?.total + adultVolunteerCounts?.total + otherContractCounts?.total} HCP eligible to have worked at this healthcare facility for at least 1 day during the week of data collection.
        <br />
        ** There are a total of <a className={style.link} onClick={() => getMissingEmployees()}>{missingEmployeeCount()} employees</a> with no record of COVID-19 immunization for the current season.
      </div>
      { missingEmployeeNames.length > 0 &&
        <div className={style.missingEmployeeModal}>
          <div className={style.missingEmployeeModalContent}>
            <div className={style.missingEmployeeModalHeader}>
              Missing Immunization Records
            </div>
            <div className={style.missingEmployeeModalBody}>
              { missingEmployeeNames.map((employee) => {
                return ( <div className={style.missingEmployeeModalBodyCell}>{employee}</div> )
              })}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default ImmunizationMetrics;