import React from 'react';
import style from './Welcome.module.scss';
import NavBar from './NavBar';

const Welcome = (user) => {
  return (
    <>
    {/* <img className={style.backgroundImage} src={"background-image.webp"} alt="test" /> */}
    <NavBar user={user}/>
    </>
  );
};

export default Welcome;