import React, { useState } from 'react'
import AdminAccounts from './AdminAccounts'
import AdminMenu from './AdminMenu'
import CreateAccount from './CreateAccount'
import ManageEmployees from './ManageEmployees'
import CreateUpdateEmployeeForm from './CreateUpdateEmployeeForm'
import style from './AdminSettings.module.scss'
import ImmunizationMetrics from './ImmunizationMetrics'

const AdminSettings = () => {
  const [selectedOption, setSelectedOption] = useState('accounts')
  const [menuOpen, setMenuOpen] = useState(false)
  
  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const handleSelectedOption = (option) => {
    handleToggleMenu()
    setSelectedOption(option)
  }

  return (
    <div className={style.settingsContainer}>
      <div className={style.adminMenu}>
        <AdminMenu setSelectedOption={setSelectedOption}/>
      </div>
      <div className={style.mobileMenuContainer}>
        <div className={menuOpen ? style.adminMenuMobile : style.adminMenuMobileActive}>
          <AdminMenu setSelectedOption={handleSelectedOption}/>
        </div>
        <div className={style.hamburgerContainer} onClick={handleToggleMenu}>
          <div className={menuOpen ? style.arrow : style.arrowActive}>
            <span className={style.upperArrow}></span>
            <span className={style.lowerArrow}></span>
            <span className={style.secondUpperArrow}></span>
            <span className={style.secondLowerArrow}></span>
          </div>
        </div>
      </div>
      

      { selectedOption === 'Accounts' &&   
      <div className={style.adminAccounts}>
        <AdminAccounts />
      </div>}
      { selectedOption === 'createUser' &&
        <div className={style.createAccount}>
          <CreateAccount />
        </div>
      }
      { selectedOption === 'manageEmployees' &&
        <div className={style.manageEmployees}>
          <ManageEmployees />
        </div>
      }
      { selectedOption === 'createEmployee' &&
        <div className={style.createEmployee}>
          <CreateUpdateEmployeeForm />
        </div>
      }
      { selectedOption === 'metrics' &&
        <div className={style.createEmployee}>
          <ImmunizationMetrics />
        </div>
      }
    </div>
  )
}

export default AdminSettings