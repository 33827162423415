import React, {useState} from 'react'
import style from './Login.module.scss'
import axios from 'axios'
import FlashMessage from '../UtilityComponents/FlashMessage'

const Login = () => {
  // const [flashMessageVisible, setFlashMessageVisible] = useState(false)
  const [flashMessageType, setFlashMessageType] = useState('')
  const [flashMessage, setFlashMessage] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const formObject = {}
    formObject.user = Object.fromEntries(formData.entries())
    try {
      const response = await axios.post('/login', formObject, {
        headers: ReactOnRails.authenticityHeaders(),
      })
      if (response.status === 200) {
        window.location.href = '/ask'
      }
    } catch (err) {
      if (err.response.data.error === 'invalid') {
        // setFlashMessageVisible(true)
        setFlashMessageType('alert')
        setFlashMessage('Invalid email or password')
      } else {
        alert(err.response.data.error)
      }
      e.target.reset()
    }

  }

  return (
    <>
      <FlashMessage {... { [flashMessageType]: flashMessage}} />
      <div className={style.loginFormContainer}>
        <form className={style.loginForm} onSubmit={handleSubmit}>
          <label>
            <input
              className={style.loginTextBox}
              type="email"
              name="email"
              id="email"
              placeholder="Email"
            />
          </label>
          <label>
            <input
              className={style.loginTextBox}
              type="password"
              name="password"
              id="password"
              placeholder="Password"
            />
          </label>
          <button type="submit">Login</button>
        </form>
      </div>
    </>
  )
}

export default Login