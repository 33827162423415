import React, { useState } from 'react'
import axios from 'axios'
import ReactOnRails from 'react-on-rails'
import style from './ContactList.module.scss'

const ContactList = (contacts) => {
  const [file, setFile] = useState(null);
  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData()

    if (file) {
      formData.append("file", file);
      const response = axios.post("/import_contacts", formData, {
        headers: ReactOnRails.authenticityHeaders({"Content-Type": "multipart/form-data"}),
      });
    }
  };

	return (
		<>
			{/* <table className={style.contactList}>
				<tr>
					<th>First Name</th>
					<th>Last Name</th>
					<th>Phone Number</th>
					<th>Email</th>
				</tr>
					{Object.values(contacts).map((contact) => (
						<tr key={contact.first_name}>
							<td className={style.item}>{contact.first_name}</td>
							<td className={style.item}>{contact.last_name}</td>
							<td className={style.item}>{contact.phone_number}</td>
							<td className={style.item}>{contact.email}</td>
						</tr>
						))
					}
			</table> */}
      <form>
        <input type={"file"} accept={".csv"} onChange={handleOnChange}/>
        <button onClick={handleOnSubmit}>Import CSV</button>
      </form>
		</>
	)
}

export default ContactList