import React, { useState, useEffect } from 'react'
import axios from 'axios'
import style from './ManageEmployees.module.scss'
import Select from "react-select"
import Modal from '../Modal'
import CreateUpdateEmployeeForm from './CreateUpdateEmployeeForm'
import FlashMessage from '../UtilityComponents/FlashMessage'

const ManageEmployees = ({employee}) => {
  const [statusFilter, setStatusFilter] = useState("active")
  const [employees, setEmployees] = useState([])
  const [selectedEmployee, setSelectedEmployee] = useState('')
  const [updateModalVisible, setUpdateModalVisible] = useState(false)
  const [subModalVisible, setSubModalVisible] = useState(false)
  const [supervisors, setSupervisors] = useState([])
  const [selectedSupervisor, setSelectedSupervisor] = useState(null)
  const [employeeTerminated, setEmployeeTerminated] = useState(false)
  const [flashMessageVisible, setFlashMessageVisible] = useState(false)
  const [flashMessageType, setFlashMessageType] = useState('')
  const [flashMessage, setFlashMessage] = useState('')
  const [terminationModalVisible, setTerminationModalVisible] = useState(false)

  useEffect(() => {
    if(employee) { setSelectedEmployee(employee) }
    const fetchEmployees = async () => {
      const response = await axios.get('/employees', {
        params: {
          filters: [statusFilter]
        }
      });

      const employees = response.data.employees
      setEmployees(employees)
    }
    fetchEmployees()
    }, [statusFilter, employeeTerminated]
  )

  const handleEmployeeSelect = async (e) => {
    const employeeId = e.value;
    const response = await axios.get(`/employees/${employeeId}`);
    const employee = response.data;
    setSelectedEmployee(employee);
  }

  const handleUpdateEmployee = (updatedEmployee, updateResponse) => {
    hideModal();
    if (updateResponse === 200) {
      setFlashMessageVisible(true);
      setFlashMessageType('notice');
      setFlashMessage(`${updatedEmployee.first_name} has been updated.`);
    } else {
      setFlashMessageVisible(true);
      setFlashMessageType('alert');
      setFlashMessage(updateResponse);
    }
  }

  const handleTerminateEmployee = async () => {
    setTerminationModalVisible(false);
    try {
      const response = await axios.delete(`/employees/${selectedEmployee.id}`, {
        headers: ReactOnRails.authenticityHeaders()
      });

      if (response.status === 200) {
        setFlashMessageVisible(true);
        setFlashMessageType('notice');
        setFlashMessage(`${selectedEmployee.first_name} has been terminated.`);
        setSelectedEmployee(null)
        setEmployeeTerminated(true)
      }
    }
    catch(err) {
      if(err.response.status === 422) {
        setSupervisors(err.response.data.supervisors);
        setSubModalVisible(true);
      } else {
        setFlashMessageVisible(true);
        setFlashMessageType('alert');
        setFlashMessage('An error occurred while deleting the employee');
      }
    }
  }

  const handleUpdateSupervisorAndTerminate = async () => {
    const subordinate_ids = selectedEmployee.subordinates.map((employee) => { return employee.id})
    const data = { employee_ids: subordinate_ids, supervisor_id: selectedSupervisor.value }
    const response = await axios.patch(`/employees/update_supervisor`, data, {
      headers: ReactOnRails.authenticityHeaders()
    });
    handleTerminateEmployee();
    hideModal();
    setSelectedSupervisor([]);
    setSubModalVisible(false);
  }

  const showModal = () => {
    setUpdateModalVisible(true)
  }

  const hideModal = () => {
    setUpdateModalVisible(false)
    setSubModalVisible(false)
  }

  return (
    <div className={style.adminAccountsContainer}>
      {flashMessageVisible ? (<FlashMessage {... { [flashMessageType]: flashMessage}} />) : null}
      <div>Active Employees: {employees.length}</div>
      <div className={style.dropdown}>
        <Select
          className={style.dropdownBox}
          name="employee"
          id="employee"
          options={employees.map((employee) => { return { value: employee.id, label: `${employee.full_name}` } })}
          placeholder="Select an employee"
          onChange={handleEmployeeSelect}
        />
      </div>
      { selectedEmployee && (
        <div>
          <div className={style.employeeContainer}>
            { updateModalVisible && (
              <div className={style.modalContainer}>
                <Modal closeModal={hideModal}>
                  <CreateUpdateEmployeeForm 
                    employee={selectedEmployee} 
                    onUpdateEmployee={handleUpdateEmployee}
                  />
                </Modal>
              </div>
            )}
            { subModalVisible && (
              <div className={style.subModalContainer}>
                <Modal closeModal={hideModal}>
                  <div className={style.subModal}>
                    <p>This employee has the following subordinates, please select a new supervisor before deleting.</p>
                    <ul>
                      { selectedEmployee.subordinates.map((subordinate) => {
                        return <li key={subordinate.id}>{subordinate.full_name}</li>
                      })}
                    </ul>
                    < Select
                      className={style.dropdownBox}
                      name="supervisor_id"
                      id="supervisor_id"
                      options={supervisors.map((employee) => { return { value: employee.id, label: `${employee.full_name}` } })}
                      placeholder="Select Supervisor"
                      onChange={setSelectedSupervisor}
                    />
                    <button
                      className={style.updateEmployee} 
                      type="submit" 
                      onClick={handleUpdateSupervisorAndTerminate}>
                        Update
                    </button>
                    <button
                      className={style.deleteEmployee} 
                      type="update" 
                      onClick={hideModal}>
                        Cancel
                    </button>
                  </div>
                </Modal>
              </div>
            )}
            { terminationModalVisible && (
              <div className={style.modalContainer}>
                <Modal closeModal={() => {setTerminationModalVisible(false)}}>
                  <div className={style.terminationModal}>
                    <p>Are you sure you want to terminate {selectedEmployee.first_name}?</p>
                    <button
                      className={style.deleteEmployee} 
                      type="submit" 
                      onClick={handleTerminateEmployee}>
                        Terminate
                    </button>
                    <button
                      className={style.updateEmployee} 
                      type="update" 
                      onClick={() => {setTerminationModalVisible(false)}}>
                        Cancel
                    </button>
                  </div>
                </Modal>
              </div>
            )  
            }
            <div className={style.employeeInfo}>
              { selectedEmployee.status === "inactive" && ( 
                <div className={style.terminatedBanner}>Terminated</div>
              )}
              <p><b>Name</b></p>
              <p>{selectedEmployee.first_name} {selectedEmployee.last_name}</p>
              <p><b>Email</b></p>
              <p>{selectedEmployee.email}</p>
              <p><b>Phone</b></p>
              <p>{selectedEmployee.phone_number}</p>
              <p><b>Employee Type</b></p>
              <p>{selectedEmployee.employee_type}</p>
              { selectedEmployee.employee_type === "Payroll" && (
                <div>
                  <p><b>Leadership</b></p>
                  <p>{selectedEmployee.leadership ? 'Yes' : 'No'}</p>
                  <p><b>Department</b></p>
                  <p>{selectedEmployee.department}</p>
                  <p><b>Position</b></p>
                  <p>{selectedEmployee.position}</p>
                  <p><b>Supervisor</b></p>
                  <p>{selectedEmployee.supervisor_name}</p>
                </div>
              )}
              <button 
                className={style.updateEmployee} 
                type="update" 
                onClick={showModal}>
                  Update Employee
              </button>
              <button
                className={style.deleteEmployee} 
                type="submit" 
                onClick={() => {setTerminationModalVisible(true)}}>
                  Terminate Employee
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ManageEmployees;
