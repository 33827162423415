import React from 'react'
import style from './HomePage.module.scss'

const HomePage = () => {

  const images = []
  for (let i = 1; i < 19; i++) {
    images.push(`home-images/${i}.webp`)
  }

  return (
    <div className={style.bodyPage}>
      <div className={style.ourStory}>
        <h1>Our Story</h1>
        <p>
          Park Forest Care Center, Inc. is an intermediate long-term care facility dedicated to the Westminster Community and its residents since 1963. 
          We support positivity, creativity, and innovation throughout all facility systems and strive to maintain clinical and psycho-social excellence 
          for the residents we care for. Park Forest believes in kind, compassionate, and understanding leadership and accountability. We are loyal to 
          our mission and vision and believe in doing what is right by our residents and employees. 
        </p>
      </div>
      <div>
        <div className={style.imageDiv} >
          <img src={images[0]} className={style.image1} alt="" />
          <img src={images[1]} className={style.image2} alt="" />
          <img src={images[2]} className={style.image3} alt="" />
          <img src={images[3]} className={style.image4} alt="" />
        </div>
      </div>
      <div>
        <div className={style.footer} >
          <p>FOOTER UNDER CONSTRUCTION</p>
        </div>
      </div>
    </div>
  )
}

export default HomePage