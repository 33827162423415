import React, { useState, useEffect } from 'react'
import Select from "react-select"
import style from './TbScreen.module.scss'
import axios from 'axios'
import { DatePicker } from 'rsuite'
import "rsuite/dist/rsuite-no-reset.min.css";

const TbScreen = ({ nurses, onUpdateTbScreen }) => {
  const [previousPositiveTbSkinTest, setPreviousPositiveTbSkinTest] = useState(false)
  const [receivedOnsite, setReceivedOnsite] = useState(null)
  const [tbPpds, setTbPpds] = useState([])
  const [dateOfPositive, setDateOfPositive] = useState('');
  const [dateOfTestAdministered, setDateOfTestAdministered] = useState('');
  const [dateOfTestEvaluated, setDateOfTestEvaluated] = useState('');
  const [dateOfLastXray, setDateOfLastXray] = useState('');
  const [tbTestVisible, setTbTestVisible] = useState(false);
  const [bloodTest, setBloodTest] = useState('');
  const customStyles = {
    control: (base) => ({
      ...base,
      borderRadius: '6px',
      border: '1px solid #ddd',
      minWidth: '220px'
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: '14px',
      color: '#999',
    }),
    menu: (base) => ({
      ...base,
      fontSize: '14px',
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: '14px',
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)
    formData.append('date_of_positive', dateOfPositive)
    formData.append('tb_screen.tb_tests.date_administered', dateOfTestAdministered)
    formData.append('tb_screen.tb_tests.date_evaluated', dateOfTestEvaluated)
    formData.append('last_xray', dateOfLastXray)
    onUpdateTbScreen(formData)
  }

  const handlePreviousPositive = (e) => {
    if (e === null) {
      setPreviousPositiveTbSkinTest(false)
    } else {
      setPreviousPositiveTbSkinTest(e.value)
    }
  }

  const handleReceivedOnsite = (e) => {
    if (e === null) {
      setReceivedOnsite(null)
    } else {
      setReceivedOnsite(e.value)
    }
  }

  useEffect(() => {
    const fetchTbPpds = async () => {
      const response = await axios.get('/tb_ppds')
      const data = response.data.tb_ppds
      setTbPpds(data)
    }
    fetchTbPpds()
  }, []);

  return (
    <form className={style.tbScreenForm} onSubmit={handleSubmit}>
      <div className={style.tbScreenQuestions}>
        <div className={style.formTitle}>Tb Screen</div>
        <label htmlFor="postitive_tb_skin_test">Previous Positive TB Skin Test?
          <div className={style.subText}>
            Xray will be required if positive TB Test
          </div>
        </label>
        <Select
          styles={ customStyles }
          isClearable={true}
          name="positive_tb_skin_test"
          id="positive_tb_skin_test"
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' }
          ]}
          placeholder="Yes/No"
          onChange={(e) => handlePreviousPositive(e)}
        />
        { previousPositiveTbSkinTest && (
          <>
            <label htmlFor="date_of_positive">Date of Positive Test: *
            </label>
            < DatePicker
              className={style.datePicker}
              name="date_of_positive"
              id="date_of_positive"
              format='MM/dd/yyy'
              oneTap
              onChange={(e) => setDateOfPositive(e)}
              placeholder="MM/DD/YYYY"
              cleanable={true}
            />
            <label htmlFor="size_of_induration">Induration in mm:</label>
            <input
              className={style.textInput}
              type="number"
              name="induration"
              id="induration"
              placeholder="Enter a Number"
            />
          </>
        )}
        <label htmlFor="last_xray">Last Chest Xray:</label>
        < DatePicker
          className={style.datePicker}
          name="last_xray"
          id="last_xray"
          format='MM/dd/yyy'
          oneTap
          onChange={(e) => setDateOfLastXray(e)}
          placeholder="MM/DD/YYYY"
          cleanable={true}
          preventOverflow={true}
        />
        <label htmlFor="last_xray_result">Last Chest Xray Result:</label>
        <Select
          styles={ customStyles }
          isClearable={true}
          name="last_xray_result"
          id="last_xray_result"
          options={[
            { value: 'positive', label: 'Positive' },
            { value: 'negative', label: 'Negative' }
          ]}
          placeholder="Positive/Negative"
        />
        <label htmlFor="tb_screen.vaccinated">Vaccinated for TB? *</label>
        <Select
          styles={ customStyles }
          isClearable={true}
          name="tb_screen.vaccinated"
          id="tb_screen.vaccinated"
          options={[
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' }
          ]}
          placeholder="Yes/No"
          required={true}
        />
      </div>
      <div className={style.firstTbTest}>
        <div className={style.formTitle}>TB Test</div>
        <label htmlFor="add_tb_test">Add a TB Test?</label>
        <Select
          styles={ customStyles }
          name="add_tb_test"
          id="add_tb_test"
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' }
          ]}
          onChange={(e) => {setTbTestVisible(e.value)}}
          placeholder="Yes/No"
          required={true}
        />
        { tbTestVisible && (    
          <>
            <label htmlFor="tb_screen.tb_tests.received_onsite">Received on Site?</label>
            <Select
              styles={ customStyles }
              isClearable={true}
              name="tb_screen.tb_tests.received_onsite"
              id="tb_screen.tb_tests.received_onsite"
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' }
              ]}
              placeholder="Yes/No"
              onChange={(e) => {handleReceivedOnsite(e)}
              }
            />
             { receivedOnsite && (
              <>
                <label htmlFor="tb_screen.tb_tests.site">Site:</label>
                <Select
                  styles={ customStyles }
                  isClearable={true}
                  name="tb_screen.tb_tests.site"
                  id="tb_screen.tb_tests.site"
                  options={[
                    { value: 'left_forearm', label: 'Left Forearm' },
                    { value: 'right_forearm', label: 'Right Forearm' }
                  ]}
                  placeholder="Left/Right Forearm"
                />
                <label htmlFor="tb_screen.tb_tests.induration">Induration in mm:</label>
                <input
                  className={style.textInput}
                  type="text"
                  name="tb_screen.tb_tests.induration"
                  id="tb_screen.tb_tests.induration"
                />
                <label htmlFor="tb_screen.tb_tests.administered_by_id">Administered By:</label>
                <Select
                  styles={ customStyles }
                  isClearable={true}
                  name="tb_screen.tb_tests.administered_by_id"
                  id="tb_screen.tb_tests.administered_by_id"
                  options={nurses.map((nurse) => { return { value: nurse.id, label: `${nurse.full_name}` } })}
                  placeholder="Select Employee"
                />
                <label htmlFor="tb_screen.tb_tests.tb_ppd_id">PPD Used:</label>
                <Select
                  styles={ customStyles }
                  isClearable={true}
                  name="tb_screen.tb_tests.tb_ppd_id"
                  id="tb_screen.tb_tests.tb_ppd_id"
                  options={tbPpds.map((tbPpd) => { return { value: tbPpd.id, label: `${tbPpd.brand}` } })}
                  placeholder="PPD Used"
                />
              </>
            ) }
            { (receivedOnsite === false && receivedOnsite != null) && (
              <>
                <label htmlFor="tb_screen.tb_tests.test_type">2-Step PPD or Blood Test?</label>
                <Select
                  styles={ customStyles }
                  isClearable={true}
                  name="tb_screen.tb_tests.test_type"
                  id="tb_screen.tb_tests.test_type"
                  options={[
                    { value: '2-step', label: '2 Step' },
                    { value: 'blood_test', label: 'Blood Test' }
                  ]}
                  onChange={(e) => setBloodTest(e?.value)}
                  placeholder="2 Step/Blood Test"
                />
                { bloodTest === 'blood_test' && (
                <>
                  <label htmlFor="tb_screen.tb_tests.blood_test_brand">Which Blood Test?</label>
                  <Select
                    styles={ customStyles }
                    isClearable={true}
                    name="tb_screen.tb_tests.blood_test_brand"
                    id="tb_screen.tb_tests.blood_test_brand"
                    options={[
                      { value: 'quantiferon', label: 'Quantiferon' },
                      { value: 't_spot', label: 'T-Spot' }
                    ]}
                    placeholder="2 Step/Blood Test"
                  />
                  </>
                )}
              </>
            )}
            { receivedOnsite != null && (
              <>
                <label htmlFor="tb_screen.tb_tests.date_administered">Date Administered:</label>
                <DatePicker
                  className={style.datePicker}
                  name="tb_screen.tb_tests.date_administered"
                  id="tb_screen.tb_tests.date_administered"
                  format='MM/dd/yyy'
                  oneTap
                  onChange={(e) => setDateOfTestAdministered(e)}
                  placeholder="MM/DD/YYYY"
                  cleanable={true}
                />
                <label htmlFor="tb_screen.tb_tests.date_evaluated">Date Evaluated:</label>
                <DatePicker
                  className={style.datePicker}
                  name="tb_screen.tb_tests.date_evaluated"
                  id="tb_screen.tb_tests.date_evaluated"
                  format='MM/dd/yyy'
                  oneTap
                  onChange={(e) => setDateOfTestEvaluated(e)}
                  placeholder="MM/DD/YYYY"
                  cleanable={true}
                />
                <label htmlFor="tb_screen.tb_tests.result">Results:</label>
                <Select
                  styles={ customStyles }
                  isClearable={true}
                  name="tb_screen.tb_tests.result"
                  id="tb_screen.tb_tests.result"
                  options={[
                    { value: 'positive', label: 'Positive' },
                    { value: 'negative', label: 'Negative' }
                  ]}
                  placeholder="Positive/Negative"
                />
              </>
            )}
            <br />
          </>
        )}
      </div>
      <div className={style.submitButton}>
        <button className={style.defaultButton} type='submit' value='submit'>Save</button>
      </div>
    </form>
  )
}

export default TbScreen